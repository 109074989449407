import React, {useState} from 'react';
import {Heading, HStack, VStack, Box, SimpleGrid, Button, Spinner} from '@chakra-ui/react';
import FilmCard from '../FilmCard';

const SearchResultsScene = (props) => {
    const { films, setScene, setSelectedFilm, filterIsLoading } = props;
    return (
        <>
            {filterIsLoading ? <Spinner p = '20' color= 'qTurquoise.500' size={'xl'} /> : (
                films?.results?.length === 0 ? (
                    <Box>
                        No results found, try another search
                    </Box>
                ) : (
                    <SimpleGrid columns={[2,6]} spacing={[9,20]} paddingTop={4} >
                        {films?.results?.map((film) => {
                            return (
                                <div>
                                    <FilmCard
                                    // filmGenre={film.genre_ids}
                                   // filmGenre={film.genre_ids.0} ?

                                        filmId={film.id}
                                        title={film.title}
                                        year={film.release_date}
                                        imagePath={film.backdrop_path ? film.backdrop_path : film.poster_path}
                                        avgQRating={film.avgQRating}
                                        setScene={setScene}
                                        setSelectedFilm={setSelectedFilm}
                                    />
                                </div>
                            );
                        })}
                    </SimpleGrid>
                )
            )}
        </>
    );
};

export default SearchResultsScene;