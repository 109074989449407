import React, {useState, useEffect} from 'react';
import { useMedia } from 'react-use';
import {
    Heading,
    Text,
    Box,
    SimpleGrid,
    Spinner,
    Spacer,
    VStack,
    Container,
} from '@chakra-ui/react';

import FilmCard from '../FilmCard';
import { searchTmdbFilmById } from '../../api';


const HomeScene = (props) => {
    const { setScene, setSelectedFilm } = props;
    const [featuredFilmsArray, setFeaturedFilmsArray] = useState([])
    const [featureIsLoading, setFeatureIsLoading] = useState(true);

    //Reset These to Different Feature film ID's for an update to Front Page
    // in order to find the Id go to API and use the Search-movie route with the name of the movie then grab the id to be manually update
    // in our own code below

    // Bullet Train
    const featuredFilmId0 = '718930';
    // the creator
    const featuredFilmId1 = '670292';
    // Love Actually
    const featuredFilmId2 = '508';
    // Amsterdam
    const featuredFilmId3 = '664469';
    const isMobile = useMedia('(max-width: 767px)');

    const getFilm = async (featuredFilmId) => {
        try{
            const filmIdResponse = await searchTmdbFilmById(featuredFilmId);
            return filmIdResponse;
        }
        catch (error) {
            console.log(error + "error in feature film call")
        };
    };

    useEffect(async () => {
        const tempFilmsArray = [];

        const film0 = await getFilm(featuredFilmId0);
        tempFilmsArray.push(film0);

        const film1 = await getFilm(featuredFilmId1);
        tempFilmsArray.push(film1);

        const film2 = await getFilm(featuredFilmId2);
        tempFilmsArray.push(film2);

        const film3 = await getFilm(featuredFilmId3);
        tempFilmsArray.push(film3);

        setFeaturedFilmsArray(tempFilmsArray);

        setFeatureIsLoading(false);

    }, [])

    return (
        <>
            {!isMobile ? (
                <> 
                <Heading colorScheme='qTurquoise' variant='custom-tq' fontFamily="qFont" fontSize='8xl'>
                QUARTILE
                </Heading>
                <Heading fontFamily="categoryFont" fontSize={'md'} letterSpacing="widest">
                Film Rating System
                </Heading>
            </>
            ) : null} 

            <Spacer></Spacer>

            {!isMobile ? (
                <> 
                <Container  paddingTop='20'  maxW= 'container.xl'>
                    <Heading align='left' fontFamily="categoryFont" paddingTop='6' fontSize='sm' fontWeight='lighest' letterSpacing="0.4em" marginBottom= '-8'>
                            Featured Films
                    </Heading>
                </Container>
            </>
            ) : 
                <Container   paddingLeft='12' paddingTop='3' maxW= 'container.xl'>
                    {/* <Heading align='left' fontFamily="categoryFont" fontSize='xs' fontWeight='lighest' letterSpacing="0.4em" >
                            Films
                    </Heading> */}
                    <Heading align='left' fontFamily="categoryFont" fontSize='xs' fontWeight='lighest' letterSpacing="0.4em" marginBottom='-4' >
                            Featured
                    </Heading>
                </Container>
            } 


            
                
            { featureIsLoading ? < Spinner p = '20' color= 'qTurquoise.500' size={'xl'}/> : (
                <SimpleGrid  columns={[2, 4]} spacing={[4,10]} >
                    <Box padding={['10px','30px']}>
                        <div >
                            <FilmCard
                                    title={featuredFilmsArray[0]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[0]?.release_date}
                                    imagePath={featuredFilmsArray[0]?.backdrop_path ? featuredFilmsArray[0]?.backdrop_path : featuredFilmsArray[0]?.poster_path}
                                    filmId={featuredFilmsArray[0]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>

                    <Box padding={['10px','30px']}>
                        <div >
                            <FilmCard
                                    title={featuredFilmsArray[1]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[1]?.release_date}
                                    imagePath={featuredFilmsArray[1]?.backdrop_path ? featuredFilmsArray[1]?.backdrop_path : featuredFilmsArray[1]?.poster_path}
                                    filmId={featuredFilmsArray[1]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>

                    <Box padding={['10px','30px']}>
                        <div >
                            <FilmCard
                                    title={featuredFilmsArray[2]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[2]?.release_date}
                                    imagePath={featuredFilmsArray[2]?.backdrop_path ? featuredFilmsArray[2]?.backdrop_path : featuredFilmsArray[2]?.poster_path}
                                    filmId={featuredFilmsArray[2]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>

                    <Box padding={['10px','30px']}>
                        <div >
                             <FilmCard
                                    title={featuredFilmsArray[3]?.title}
                                    isFeaturedCard={true}
                                    year={featuredFilmsArray[3]?.release_date}
                                    imagePath={featuredFilmsArray[3]?.backdrop_path ? featuredFilmsArray[3]?.backdrop_path : featuredFilmsArray[3]?.poster_path}
                                    filmId={featuredFilmsArray[3]?.id}
                                    setScene={setScene}
                                    setSelectedFilm={setSelectedFilm}
                                />
                        </div>
                    </Box>


                </SimpleGrid>

            )}



        </>
    );
};

export default HomeScene;