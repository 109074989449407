import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    Box,
    useToast,
    useColorMode,
} from '@chakra-ui/react';

import './App.css';
import './index.css';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import AboutPage from "./components/AboutPage";
import {logoutApi} from "./api";

function App() {
  const [page, setPage] = useState('HOME');
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [customSwitchChecked, setCustomSwitchChecked] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const isMobile = useMedia('(max-width: 767px)');

  React.useEffect(() => {
    const loggedInUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    const time = Date.now();
    //if the user is logged in less than 4hrs in ms then stay logged in 14400000, which is set in the backend when we
    //  generate the token
    if (loggedInUserInfo?.userId  && (time - loggedInUserInfo.timestamp  < 14400000)) {
      setUserId(loggedInUserInfo?.userId);
      setLoggedIn(true);
    }
  }, [])

    React.useEffect(() => {
        toast({
            title: 'Thanks For Being an Alpha User',
            description:
                " We have a lot planned for Quartile's future. It is best to stay in dark mode for now." +
                " Shortly, we are planning on adding a suite of small features to help improve the alpha. We appreciate any feedback you have about the site or ideas for new features you would like to see. Contact us at info@quartilefilm.io." +
                " If you'd like to support us, you can through the BUY ME A COFFEE widget, found at the bottom right of the screen. Most importantly though, enjoy rating & discovering some films! ",
            status: 'warning',
            duration: 55000,
            position: isMobile ? 'top' : 'bottom-left',
            isClosable: true,
        });
    }, [])


  //Logout Function
   const handleLogout = async () => {
    try{
      debugger;
      setLoggedIn(false);
      localStorage.clear();
      await logoutApi();
      toast({
        title: 'Successfully Logged Out',
        description: "login or create an account again to rate more films",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
    catch (e) {
      console.log('ERROR logging in to account in RegisterPage: ', e);
    }
  }

  return (
    <Box className="App" >
      {page === 'HOME' &&
        <HomePage
            setPage={setPage}
            page={page}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
            handleLogout={handleLogout}
            userId={userId}
            customSwitchChecked={customSwitchChecked}
            setCustomSwitchChecked={setCustomSwitchChecked}
            colorMode={colorMode}
            toggleColorMode={toggleColorMode}
        />
      }
      {page === 'ABOUT' &&
        <AboutPage
            setPage={setPage}
            page={page}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
            handleLogout={handleLogout}
            customSwitchChecked={customSwitchChecked}
            setCustomSwitchChecked={setCustomSwitchChecked}
            colorMode={colorMode}
            toggleColorMode={toggleColorMode}
        />
      }
      {page === 'LOGIN' && 
        <LoginPage
            setPage={setPage}
            page={page}
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
            handleLogout={handleLogout}
            setUserId={setUserId}
            customSwitchChecked={customSwitchChecked}
            setCustomSwitchChecked={setCustomSwitchChecked}
            colorMode={colorMode}
            toggleColorMode={toggleColorMode}
        />
      }
    </Box>
  );
}

export default App;
