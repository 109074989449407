import React from 'react';
import {Box, Image, Badge, VStack, Heading, StackDivider, LinkBox, LinkOverlay, useColorModeValue, Button, Spinner} from '@chakra-ui/react';
import {getQuartileFilm, getUserRating} from '../api';

const FilmCard = (props) => {

    const [isLoading, setIsLoading] = React.useState(true);
    const [quartileFilmData, setQuartileFilmData] = React.useState({});
    const {
        filmId= '',
        imagePath = '',
        title = 'defaultTitle',
        year='defaultYear',
        isFeaturedCard= false,
        avgQRating,
        setScene={setScene},
        setSelectedFilm={setSelectedFilm}
    } = props;

    const borderColor = useColorModeValue("black", "white");
    const textColor = useColorModeValue("black", "white");
    const bgColor = useColorModeValue("white", "black");


    // Image Logic - HomePage.jsx is passing backdrop img, if not there, pass poster img, if not there use default
    const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
    const defaultImage = 'https://bit.ly/2Z4KKcF';
    const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;

    // Year Logic - If no trimmedYear exists display nothing for year
    const trimmedYear = parseInt(year);

    // TODO: Pass in full film object to the Film Card, needs to be set below
    const handleClick = () => {
        const filmDetails = {
            filmId,
            fullImgPath,
            imagePath,
            title,
            year,
            isFeaturedFilm: isFeaturedCard
        };
        setSelectedFilm(filmDetails);
        setScene('RATE_FILM');
    };


    //extract qRatingAverage
    React.useEffect(() => {
        const loadFilmRatings = async () => {
            const film = await getQuartileFilm(filmId);
            setQuartileFilmData(film);
            setIsLoading(false);
        }
        loadFilmRatings();
    }, [filmId]);

    const renderQRating = () => {
        if (avgQRating) {
            return avgQRating.toFixed(1);
        }
        if (quartileFilmData?.filmQRatingAverage) {
            return quartileFilmData?.filmQRatingAverage.toFixed(1);
        }
        return '?';
    }

    const renderFilmCard = (isFeaturedCard) => {
        if (!isFeaturedCard) {
            return (
                //    feature card false
                <button onClick={() => {handleClick()}}>
                    <VStack class= "containerdiv">
                        <Image
                            src = {fullImgPath}
                            alt="Movie Pic Placeholder"
                            class = "filmCardFormat"
                            style={{ border: `3px solid ${borderColor}` }}
                        />

                        <VStack class={"QCardFormat"} style={{ border: `3px solid ${borderColor}`, backgroundColor: bgColor }} >
                            <Heading  class="QCardNumberFormat" justify-content={"flex-end"} >
                                {renderQRating()}
                            </Heading>
                            <Image
                                src = "QLogoTest1.png"
                                alt="q Logo "
                                class = "QCardIconFormat"
                            />
                        </VStack>

                        <Heading class = "QCardTitleFormat" letterSpacing={'wide'} >
                            {title}
                        </Heading>
                        {trimmedYear && <Heading class={'QCardYearFormat'}>
                            {trimmedYear}
                        </Heading>}
                    </VStack>
                </button>

            );
        } else {
            return (
                //    feature card true
                //    custom class' for formatting the home page CARDS
                <button onClick={() => {handleClick()}}>
                    <VStack class= "featureFilmCardVstack" >
                        <Image
                            src = {fullImgPath}
                            alt="Movie Pic Placeholder"
                            class = "featureFilmCardFormat "
                            style={{ border: `3px solid ${borderColor}` }}
                        />

                        <VStack class={"featureQCardFormat"} style={{ border: `3px solid ${borderColor}`, backgroundColor: bgColor }} >
                            <Heading  class="featureQCardNumberFormat"   justify-content="flex-end" >
                                {isLoading ? <Spinner /> : quartileFilmData?.filmQRatingAverage ? quartileFilmData?.filmQRatingAverage.toFixed(1) : '?'}
                            </Heading>
                            <Image
                                src = "QLogoTest1.png"
                                alt="q Logo "
                                class = "featureQCardIconFormat"
                            />
                        </VStack>

                        <Heading class = "featureQCardTitleFormat" >
                            {title}
                        </Heading>
                        {trimmedYear && <Heading class ='featureQCardYearFormat' >
                            {trimmedYear}
                        </Heading>}
                    </VStack>
                </button>

            );
        }
    }

    return (
        <>
            {renderFilmCard(isFeaturedCard)}
        </>
    );


}

export default FilmCard;