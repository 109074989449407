import React, { useState,} from 'react';
import {
    HStack,
    VStack,
    Button,
    chakra,
    Box,
    Center,
    Image,
    useCheckbox,
    Text,
    useCheckboxGroup,
    Input,
    Icon,
    IconButton,
    Heading,
    useToast,
    useColorModeValue,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import {searchTmdbFilms, getTopFilmsByCategory, searchTmdbFilmById} from '../api';
import CustomSwitch from "./CustomSwitch";


function CustomCheckbox(props) {
    const { state, getInputProps, getLabelProps, htmlProps } =
        useCheckbox(props);
    
    if (state.isChecked) {
        return (
            <chakra.label
                display='flex'
                flexDirection='row'
                alignItems='center'
                gridColumnGap={2}
                bg='black.50'
                border='3px solid'
                borderColor='qPurple.500'
                rounded='lg'
                px={3}
                py={1}
                cursor='pointer'
                {...htmlProps}
            >
                <input {...getInputProps()} hidden />
                <Text
                    color={useColorModeValue('black', 'white')}
                    fontFamily='categoryFont'
                    letterSpacing="widest"
                    fontWeight='semibold'
                    {...getLabelProps()}
                    fontSize={'md'}
                    >    
                    {props.value}
                </Text>
            </chakra.label>
        )
    }
    return (
        <chakra.label
            display='flex'
            flexDirection='row'
            alignItems='center'
            gridColumnGap={2}
            bg='black.50'
            border='3px solid'
            // borderColor='black'
            borderColor={useColorModeValue('white', 'black')}
            rounded='lg'
            px={3}
            py={1}
            cursor='pointer'
            variant = {['mobile','baseStyle']}
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Text
                // color="white"
                color={useColorModeValue('black', 'white')}
                fontFamily='categoryFont'
                letterSpacing="widest"
                fontWeight='semibold'
                // fontSize= '18px'
                {...getLabelProps()}
                fontSize={'md'}
                >
                
                {props.value}
            </Text>
        </chakra.label>
    )
}

function CategoryCheckbox(setCategories, homeClicked, setHomeClicked) {
    const { value, getCheckboxProps, setValue } = useCheckboxGroup({});

    React.useEffect(() => {
        setCategories(value);
    }, [value]);

    React.useEffect(() => {
        setValue([]);
        setHomeClicked(false);
    }, [homeClicked]);

    return (
        <VStack paddingTop='6' width='96%' spacing='' align='end' paddingBottom='4' >
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Plot' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Acting' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Cinematography' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Novelty' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Ending' })} />
        </VStack>
    )
}

const MobileTopNav = (props) => {

    const {
        setScene,
        setPage,
        loggedIn,
        handleLogout,
        setSearchFilmsResponse,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        setFilterIsLoading
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');
    const [categories, setCategories] = useState([]);
    const [homeClicked, setHomeClicked] = useState(false);
    const toast = useToast();
    const [toastDisplayed, setToastDisplayed] = useState(false);

    // LIGHT/DARK UI declarations
    // const value = useColorModeValue(lightModeValue, darkModeValue)
    // const borderBottomSearch = useColorModeValue('blue', 'red.200');
    // size is working based on this system, something about border will not let it get implimented, also possible to do
    //a variation of this through the theme.js file
    // const size = useColorModeValue('sm', 'lg');

    const CustomMenuIcon = () => (
        <img src={process.env.PUBLIC_URL + '/MenuWhite.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
      );

    const CustomMenuIconBlack = () => (
        <img src={process.env.PUBLIC_URL + '/MenuBlack.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
    );

    const onChangeKeyword = (event) => {
        setUserSearchInput(event.target.value);
    };

    const onSearch = async (event) => {
        event.preventDefault();
        const films = await searchTmdbFilms(userSearchInput);
        setSearchFilmsResponse(films);
        setScene('SEARCH_RESULTS');
    }

    const onHomeClick = () => {
        document.querySelector("input").value = "";
        setCategories([]);
        setScene('HOME');
        setHomeClicked(true);
        setToastDisplayed(false);
    }

    React.useEffect(() => {
        const onCategoryFilter = async () => {
            try {
                document.querySelector("input").value = "";
                setFilterIsLoading(true);
                setScene('SEARCH_RESULTS');
                const films = await getTopFilmsByCategory(categories);
                let searchResults = {
                    results: films
                };
                setSearchFilmsResponse(searchResults);
                setFilterIsLoading(false);
                if (!toastDisplayed) {
                    toast({
                      title: "Category Filter",
                      description:
                        "Only films rated 4 - Well Above Average in the selected category will be displayed. Multiple categories may be selected at once, only films rated 4 - Well Above Average in every selected categories will be displayed. -- This filter uses AND Logic, not OR Logic", 
                      status: "success",
                      position: "top",
                      duration: 12000,
                      isClosable: true
                    });
            
                    setToastDisplayed(true);
                  }
            } catch (e) {
                setFilterIsLoading(false);
                // console.log("DED: ", e);
            }
        };
        if (categories.length > 0) {
            onCategoryFilter();
        }
        // TODO: check previous categories value is larger than 0 her
        // this will work to uncheck the filters but it breaks the back button
        // if (categories.length === 0) {
        //     setScene('HOME');
        //     setSearchFilmsResponse([]);
        // }

    }, [categories]);

    const callLogOut = () => {
        try {
            handleLogout();
            setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <Button
                    width='100%'
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                    setPage('LOGIN')
                }}> Login </Button>
            );
        } else {
            return (
                <Button
                    width='100%'
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        callLogOut();
                    }}
                >
                    Logout
                </Button>
            );
        }
    }


    return (
        <>

            <HStack marginTop={'12px'} justifyContent='space-between' width={'100%'}  >
                <VStack >
                    <Button h={'100%'} w={'100%'} variant='ghost' 
                        onClick={() => {onHomeClick()}} >
                            <VStack align='start' spacing='.1px'>
                            <Heading colorScheme ='qTurquoise' variant='custom-tq' fontFamily ="qFont" fontSize='5xl' letterSpacing='tighter'>
                            QUARTILE
                            </Heading>
                            <Heading paddingLeft='8px' fontFamily="categoryFont" fontSize='xs' letterSpacing="widest" paddingRight={'12'}>
                            Film Rating System 
                            </Heading>
                            </VStack> 
                    </Button>
                </VStack>
                   
                <VStack paddingLeft='16'paddingBottom={'6'}>
                    <Menu isLazy>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={useColorModeValue(<CustomMenuIconBlack/>, <CustomMenuIcon/>)}
                            variant='ghost'
                        /> 

                        <MenuList
                            style={{
                                backgroundColor: '#6c39ac',
                                borderColor: 'white',
                            }} 
                        >
                            {loggedIn ? (
                            <MenuItem 
                                // width='100%' 
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {callLogOut();}}
                                > Logout
                            </MenuItem>
                            ) : (
                            <MenuItem 
                                // width='100%' 
                                // textAlign='center'
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => setPage('LOGIN')}
                                >
                                    Login
                            </MenuItem>
                            )}

                            <MenuItem
                                // width='100%' 
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {setPage('ABOUT')}}
                            >
                                About
                            </MenuItem>

                            <MenuItem> 
                                <CustomSwitch
                                onColor={'#000'}
                                offColor={'#FFF'}
                                isChecked={customSwitchChecked}
                                handleSwitch={() =>{
                                    setCustomSwitchChecked(!customSwitchChecked);
                                    toggleColorMode();
                                }}
                                >
                                </CustomSwitch>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>  
            </HStack>

            <Box width={'88%'} pt='5'>
            <form onSubmit={onSearch} >
                       <Input 
                       variant="filled" 
                       placeholder="Search" 
                       _placeholder={{ color: '#94B0B3' }}
                       textAlign='center'
                       width='100%'
                       onChange={onChangeKeyword}
                       onSubmit={onSearch}
                       style={{
                           backgroundColor: '#6c39ac',
                           borderColor: '#6c39ac',
                           borderRadius: '12px',
                       }} 
                       />
            </form>
            </Box>
            
            {CategoryCheckbox(setCategories, homeClicked, setHomeClicked)}
        </>
    );
};

export default MobileTopNav;