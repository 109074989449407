import React, { useState } from 'react';
import {
    HStack,
    VStack,
    Button,
    Input,
    IconButton,
    Switch,
    FormControl,
    Box,
    StackDivider,
    useColorMode,
    Heading, Image
} from '@chakra-ui/react';
import CustomSwitch from "./CustomSwitch";
import { searchFilms } from '../api';
import {theme} from "@chakra-ui/react";
import TopNav from "./TopNav";
import handleLogout from "./TopNav"

//think  we might be able to take out the values to search in this nav, because search won't be displayed when using this?
//unless we need to pass the search value from the previous view to this one?
const RateNav = (props) => {

    const {
        setScene,
        page,
        setPage,
        setLoggedIn,
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');

    const onChangeKeyword = (event) => {
        setUserSearchInput(event.target.value);
    };

    // const onSearch = async (event) => {
    //     console.log('andrew called onSearch with: ', userSearchInput);
    //     event.preventDefault();
    //     // setIsLoading(true)
    //     const films = await searchFilms(userSearchInput);
    //     setSearchFilmsResponse(films);
    //     setScene('HOME');
    //     // setIsLoading(false)
    // }


    const callLogOut = () => {
        try {
            handleLogout();
            setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }

    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                    setPage('LOGIN')
                }}> Login </Button>
            );
        } else {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        callLogOut();

                    }}
                >
                    Logout
                </Button>
            );
        }
    }

    return (
        <>
            <HStack marginTop={'8px'}  position = 'absolute' width={'100%'} spacing='200px' justifyContent={"space-between"} >
                <VStack class= " alphaContainerdiv"  >
                    <Button id="home_button" alignSelf={'flex-start'}  minWidth='150px' variant='ghost' onClick={() => {setScene('HOME')}}></Button>
                    {/* <Image
                        src = "alpha.png"
                        alt="q Logo "
                        class = "alphaIconFormat"
                    /> */}
                </VStack>
                <VStack spacing={'-4px'} >
                    {renderLoginButton(loggedIn)}
                    <Button
                        letterSpacing='wider'
                        variant='ghost'
                        fontFamily="buttonFont"
                        onClick={() => {setPage('ABOUT')}}>
                        About
                    </Button>
                    <CustomSwitch
                        onColor={'#000'}
                        offColor={'#FFF'}
                        isChecked={customSwitchChecked}
                        handleSwitch={() =>{
                            setCustomSwitchChecked(!customSwitchChecked);
                            toggleColorMode();
                        }}
                    >
                    </CustomSwitch>
                </VStack>
            </HStack>


        </>
    );
};

export default RateNav;