import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import VerifyPage from './components/VerifyPage';

function Routing() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<App />}></Route>
        <Route path="/verify-email/" element={<VerifyPage />}></Route>
      </Routes>
    </Router>
  );
}

export default Routing;