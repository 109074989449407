import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Text,
    Center,
    Heading,
    useToast,
} from '@chakra-ui/react';

const VerifyScene = (props) => {

    const {
        setScene,
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const toast = useToast();

    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p='72px' width='100%' justify= 'center'   >

                    {/* <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                        QUARTILE
                    </Heading> */}
                    <Text   variant='' fontFamily={"paragraphFont"}  >
                        Verify Your Email To Login In 
                    </Text>

                </VStack>
            </Center>
        </VStack>
    );
};

export default VerifyScene;