import React, { useState,} from 'react';
import {
    HStack,
    VStack,
    Button,
    chakra,
    Image,
    useCheckbox,
    Text,
    useCheckboxGroup,
    ColorMode,
    useColorModeValue,
    Input,
    useToast,
} from '@chakra-ui/react';
import {searchTmdbFilms, getTopFilmsByCategory, searchTmdbFilmById} from '../api';
import CustomSwitch from "./CustomSwitch";


function CustomCheckbox(props) {
    const { state, getInputProps, getLabelProps, htmlProps } =
        useCheckbox(props);
    const textColor = useColorModeValue("black", "white");
    const borderColorUnselected = useColorModeValue("white", "black");

    if (state.isChecked) {
        return (
            <chakra.label
                display='flex'
                flexDirection='row'
                alignItems='center'
                gridColumnGap={2}
                bg='black.50'
                border='3px solid'
                borderColor='qPurple.500'
                rounded='lg'
                px={3}
                py={1}
                cursor='pointer'
                {...htmlProps}
            >
                <input {...getInputProps()} hidden />
                <Text
                    color={textColor}
                    fontFamily='categoryFont'
                    letterSpacing="widest"
                    fontWeight='semibold'
                    {...getLabelProps()}
                    fontSize={['xs','md']}
                    >    
                    {props.value}
                </Text>
            </chakra.label>
        )
    }
    return (
        <chakra.label
            display='flex'
            flexDirection='row'
            alignItems='center'
            gridColumnGap={2}
            bg='black.50'
            border='3px solid'
            borderColor={borderColorUnselected}
            rounded='lg'
            px={3}
            py={1}
            cursor='pointer'
            variant = {['mobile','baseStyle']}
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Text
                color={textColor}
                fontFamily='categoryFont'
                letterSpacing="widest"
                fontWeight='semibold'
                // fontSize= '18px'
                {...getLabelProps()}
                fontSize={['xs','md']}
                >
                
                {props.value}
            </Text>
        </chakra.label>
    )
}

function CategoryCheckbox(setCategories, homeClicked, setHomeClicked) {
    const { value, getCheckboxProps, setValue } = useCheckboxGroup({});

    React.useEffect(() => {
        setCategories(value);
    }, [value]);

    React.useEffect(() => {
        setValue([]);
        setHomeClicked(false);
    }, [homeClicked]);

    return (
        <HStack width='100%' justifyContent={['space-between', 'space-around']} paddingBottom='4' >
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Plot' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Acting' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Cinematography' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Novelty' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Ending' })} />
        </HStack>
    )
}

const TopNav = (props) => {

    const {
        setScene,
        setPage,
        loggedIn,
        handleLogout,
        setSearchFilmsResponse,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        setFilterIsLoading
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');
    const [categories, setCategories] = useState([]);
    const [homeClicked, setHomeClicked] = useState(false);
    const toast = useToast();
    const [toastDisplayed, setToastDisplayed] = useState(false);

    // LIGHT/DARK UI declarations
    // const value = useColorModeValue(lightModeValue, darkModeValue)
    // const borderBottomSearch = useColorModeValue('blue', 'red.200');
    // size is working based on this system, something about border will not let it get implimented, also possible to do
    //a variation of this through the theme.js file
    // const size = useColorModeValue('sm', 'lg');

    const onChangeKeyword = (event) => {
        setUserSearchInput(event.target.value);
    };

    const onSearch = async (event) => {
        event.preventDefault();
        const films = await searchTmdbFilms(userSearchInput);
        setSearchFilmsResponse(films);
        setScene('SEARCH_RESULTS');
    }

    const onHomeClick = () => {
        document.querySelector("input").value = "";
        setCategories([]);
        setScene('HOME');
        setHomeClicked(true);
        setToastDisplayed(false);
    }

    React.useEffect(() => {
        const onCategoryFilter = async () => {
            try {
                document.querySelector("input").value = "";
                setFilterIsLoading(true);
                setScene('SEARCH_RESULTS');
                const films = await getTopFilmsByCategory(categories);
                let searchResults = {
                    results: films
                };
                setSearchFilmsResponse(searchResults);
                setFilterIsLoading(false);
                if (!toastDisplayed) {
                    toast({
                      title: "Category Filter",
                      description:
                        "Only films rated 4 - Well Above Average in the selected category will be displayed. Multiple categories may be selected at once, only films rated 4 - Well Above Average in every selected categories will be displayed. -- This filter uses AND Logic, not OR Logic", 
                      status: "success",
                      duration: 12000,
                      isClosable: true
                    });
            
                    setToastDisplayed(true);
                  }
            } catch (e) {
                setFilterIsLoading(false);
                // console.log("DED: ", e);
            }
        };
        if (categories.length > 0) {
            onCategoryFilter();
        }
        // TODO: check previous categories value is larger than 0 her
        // this will work to uncheck the filters but it breaks the back button
        // if (categories.length === 0) {
        //     setScene('HOME');
        //     setSearchFilmsResponse([]);
        // }

    }, [categories])
    
    const callLogOut = () => {
        try {
            handleLogout();
            setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                    setPage('LOGIN')
                }}> Login </Button>
            );
        } else {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        callLogOut();
                    }}
                >
                    Logout
                </Button>
            );
        }
    }


const customSearchBar = () => {
    const textColor = useColorModeValue("black", "white");
  
    return (
        <form onSubmit={onSearch} style={{width: '33%'}} >

             {/* <input // TODO: change this to chakra input, remove custom css
                            className="search-txt"
                            // width={'100%'}
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={onChangeKeyword}
                            onSubmit={onSearch}
                        /> */}
            {/*old chakra search bar might use later*/}
                       
            <Input
                className="search-txt"
                focusBorderColor= {textColor}
                // using is invalid to set the bottom border to white with the error border color, essentially the invalid
                // setting is set true by default since we aren't super worried about entering search wrong like an email
                // would be
                isInvalid
                errorBorderColor= {textColor}
                alignSelf='center'
                marginLeft={['0px','-104px']}
                // text-align= 'center'
                variant='flushed'
                placeholder='Search'
                onChange={onChangeKeyword}
                onSubmit={onSearch}
                width='50%'
            />
        </form>
    );
  };

    return (
        <>

            <HStack marginTop={'18px'} width={'100%'} /*spacing='200px'*/ justifyContent={"space-between"} >
                <VStack class= " alphaContainerdiv"  >
                    <Button /*id="home_button" alignSelf={'flex-start'}  minWidth={['50px', '100px', '150px']}*/ h={'100%'} w={'100%'} variant='ghost' onClick={() => {onHomeClick()}} >
                        <Image
                            src='QLogoWeb.png'
                            w={['50px', '100px', '120px']}
                            // h={['50px', '100px', '150px']}
                        />
                        {/* <Image
                            src = "alpha.png"
                            alt="q Logo "
                            class = "alphaIconFormat" // TODO: Make the alphaIconFormat reponsive
                        /> */}
                    </Button>
                </VStack>
                       
                {customSearchBar()}

                <VStack  spacing={'-4px'} >
                    {renderLoginButton(loggedIn)}
                    <Button letterSpacing='wider' variant='ghost' fontFamily="buttonFont" onClick={() => {setPage('ABOUT')}}> About </Button>
                    <CustomSwitch
                        onColor={'#000'}
                        offColor={'#FFF'}
                        isChecked={customSwitchChecked}
                        handleSwitch={() =>{
                            setCustomSwitchChecked(!customSwitchChecked);
                            toggleColorMode();
                        }}
                    >
                    </CustomSwitch>
                </VStack>
            </HStack>
            {CategoryCheckbox(setCategories, homeClicked, setHomeClicked)}
        </>
    );
};

export default TopNav;