import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Center,
    Heading,
    useToast,
} from '@chakra-ui/react';
import { loginApi } from '../../api';

const LoginScene = (props) => {

    const {
        setScene,
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        setUserId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const isEmailError = email.length >= 4 ;
    const isPasswordError = password >= 4;
    const isMobile = useMedia('(max-width: 767px)');

    const handleEmailInputChange = (e) => setEmail(e.target.value);
    const handlePasswordInputChange = (e) => setPassword(e.target.value);

    //LOGIN
    const handleLogin = async () => {
        try{
            setIsLoading(true);
            const loginResult = await loginApi({ email, password });
            setUserId(loginResult.userId);
            localStorage.setItem('userInfo', JSON.stringify({userId: loginResult.userId, timestamp: new Date().getTime()}));

            setIsLoading(false);
            toast({
                title: 'Logged In',
                description: email + " you are logged in",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setLoggedIn(true)
            setPage('HOME')

        }
        catch (e) {
            setIsLoading(false);
            toast({
                title: 'Login Failure',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            console.log('ERROR logging in to account in LoginScene: ', e);
        }
    }

    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p={['','72px']} width='100%' justify= 'center'>
                    {!isMobile ? (
                        <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                                QUARTILE
                        </Heading>
                        ) : null} 
                     {!isMobile ? (
                    <VStack p='15' width='50%' justify= 'center'>
                        <FormControl isInvalid={isEmailError}>
                            <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                            <Input
                                id='email'
                                type='email'
                                value={email}
                                onChange={handleEmailInputChange}
                            />
                            {!isEmailError ? (
                                <FormHelperText>
                
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage> Email is required. </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl isInvalid={isPasswordError}>
                            <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                            <Input
                                id='password'
                                type='password'
                                value={password}
                                onChange={handlePasswordInputChange}
                            />
                            {!isPasswordError ? (
                                <FormHelperText>
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage>Password is required.</FormErrorMessage>
                            )}
                        </FormControl>

                        <Box p={'15'} paddingTop={'25px'}>
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qTurquoise'
                                width ='120px'
                                // colorScheme='red'
                                onClick={() => {handleLogin()}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Login
                            </Button>
                        </Box>

                        <Box p={'2'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qPurple'
                                width ='120px'
                                // colorScheme='red'
                                onClick={() => {setScene('REGISTER')}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </VStack>
                    ) : 
                    <VStack paddingTop='5' width='80%' justify= 'center' spacing='0.25'>
                        <FormControl isInvalid={isEmailError}>
                            <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                            <Input
                                id='email'
                                type='email'
                                value={email}
                                onChange={handleEmailInputChange}
                            />
                            {!isEmailError ? (
                                <FormHelperText>
                
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage> Email is required. </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl isInvalid={isPasswordError}>
                            <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                            <Input
                                id='password'
                                type='password'
                                value={password}
                                onChange={handlePasswordInputChange}
                            />
                            {!isPasswordError ? (
                                <FormHelperText>
                                </FormHelperText>
                            ) : (
                                <FormErrorMessage>Password is required.</FormErrorMessage>
                            )}
                        </FormControl>

                        <Box width='100%' paddingTop={'8'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qTurquoise'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {handleLogin()}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Login
                            </Button>
                        </Box>

                        <Box width='100%' paddingTop={'10'} >
                            <Button
                                letterSpacing='wider'
                                isLoading={isLoading}
                                // colorScheme='brand'
                                colorScheme='qPurple'
                                width ='100%'
                                // colorScheme='red'
                                onClick={() => {setScene('REGISTER')}}
                                // spinner={<BeatLoader size={8} color='white' />}
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </VStack>
                    } 

                </VStack>
            </Center>
        </VStack>
    );
};

export default LoginScene;