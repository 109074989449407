import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    VStack,
} from '@chakra-ui/react';
import TopNav from './TopNav';
import MobileTopNav from './MobileTopNav';
import HomeScene from './Scenes/HomeScene';
import SearchResultsScene from "./Scenes/SearchResultsScene";
import RateFilmScene from "./Scenes/RateFilmScene";
import AltNav from "./AltNav";
import MobileAltNav from './MobileAltNav';
import RateNav from "./RateNav";
import MobileRateNav from './MobileRateNav';


const HomePage = (props) => {

    const {
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        handleLogout,
        userId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [scene, setScene] = useState('HOME');
    const [prevScene, setPrevScene] = useState('');
    const [searchFilmsResponse, setSearchFilmsResponse] = useState([]);
    const [selectedFilm, setSelectedFilm] = useState([]);
    const [filterIsLoading, setFilterIsLoading] = useState(false);


    // Mobile vs Web Nav Bar Rendering
    const isMobile = useMedia('(max-width: 767px)');

    const renderTopNav = (scene) => {
      if (isMobile) {
        if (scene === 'HOME' || scene === 'SEARCH_RESULTS') {
          return (
            <MobileTopNav
              setScene={setScene}
              setSearchFilmsResponse={setSearchFilmsResponse}
              setFilterIsLoading={setFilterIsLoading}
              setPage={setPage}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />
          );
        } else if (scene === 'RATE_FILM') {
          return (
            <MobileRateNav
              setScene={setScene}
              setPage={setPage}
              page={page}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />
          );
        } else {
          return (
            <MobileAltNav
              setScene={setScene}
              setPage={setPage}
              page={page}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />
          );
        }
      } else {
        if (scene === 'HOME' || scene === 'SEARCH_RESULTS') {
          return (
            <TopNav
              setScene={setScene}
              setSearchFilmsResponse={setSearchFilmsResponse}
              setFilterIsLoading={setFilterIsLoading}
              setPage={setPage}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
            />
          );
        } else if (scene === 'RATE_FILM') {
          return (
            <RateNav
              setScene={setScene}
              setPage={setPage}
              page={page}
              setLoggedIn={setLoggedIn}
              loggedIn={loggedIn}
              handleLogout={handleLogout}
              customSwitchChecked={customSwitchChecked}
              setCustomSwitchChecked={setCustomSwitchChecked}
              colorMode={colorMode}
              toggleColorMode={toggleColorMode}
              />
            );
        }
        else {
            return (
                <AltNav
                    setPage={setPage}
                    page={page}
                    setLoggedIn={setLoggedIn}
                    loggedIn={loggedIn}
                    handleLogout={handleLogout}
                    customSwitchChecked={customSwitchChecked}
                    setCustomSwitchChecked={setCustomSwitchChecked}
                    colorMode={colorMode}
                    toggleColorMode={toggleColorMode}
                />
            )
        }
    }
}
              
    return (
        <VStack marginTop={'-8px'}>
            {renderTopNav(scene)}
            {scene === 'HOME' && (
                <HomeScene
                    setScene={setScene}
                    setSelectedFilm={setSelectedFilm}
                />
            )}
            {scene === 'SEARCH_RESULTS' && (
                <SearchResultsScene
                    films={searchFilmsResponse}
                    setScene={setScene}
                    setSelectedFilm={setSelectedFilm}
                    filterIsLoading={filterIsLoading}
                />
            )}
            {scene === 'RATE_FILM' && (
                <RateFilmScene
                    selectedFilm={selectedFilm}
                    userId={userId}
                    setScene={setScene}
                    setPage={setPage}
                    loggedIn={loggedIn}
                    setSelectedFilm={setSelectedFilm}
                />
            )}
        </VStack>
    );
}

export default HomePage;