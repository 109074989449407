import React from 'react';
import { useMedia } from 'react-use';
import {
    HStack,
    VStack,
    Heading,
    Text,
    Image,
    Button,
    Center,
    SimpleGrid,
    Box,
    Spacer,
    useToast,
    Stack,
    Spinner,
    useColorModeValue,
    Tooltip,
    Wrap,
} from '@chakra-ui/react';
import { rateFilm, getUserRating, getQuartileFilm } from '../../api';
import FilmCard from '../FilmCard';
import Carousel from '../Carousel';
import RateCarousel from '../RateCarousel';

const RateFilmScene = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasUserRated, setHasUserRated] = React.useState(false);
    const [quartileFilmData, setQuartileFilmData] = React.useState({});
    const { selectedFilm, userId, loggedIn, setPage, setScene, setSelectedFilm } = props;
    const { filmId, fullImgPath, imagePath, title, year, isFeaturedFilm } = selectedFilm;
    const trimmedYear = parseInt(year);
    const toast = useToast();
    const isMobile = useMedia('(max-width: 767px)');
    const [isDocumentary, setIsDocumentary] = React.useState(false);

    const [qRating, setQRating] = React.useState(0);
    const [plotRating, setPlotRating] = React.useState(0);
    const [actingRating, setActingRating] = React.useState(0);
    const [cinematographyRating, setCinematographyRating] = React.useState(0);
    const [noveltyRating, setNoveltyRating] = React.useState(0);
    const [endingRating, setEndingRating] = React.useState(0);
    // const qRating = selectedFilm.rating;

    // film card light/dark declarations
    const borderColor = useColorModeValue("black", "white");
    const bgColor = useColorModeValue("white", "black")

    const CustomHStack = ({ children }) => {
        const borderColor = useColorModeValue('black', 'white');
      
        return (
          <HStack layerStyle='lightDark' width='100%' height='100%' alignItems='flex-start'  paddingBottom='0' spacing='0' borderBottom={`2px solid ${borderColor}`}>
            {children}
          </HStack>
        );
    };

    const CustomHStack2 = ({ children }) => {
        const borderColor = useColorModeValue('black', 'white');
      
        return (
          <HStack layerStyle='lightDark' width='100%' height='100%' alignItems='flex-start'  paddingBottom='0' spacing='0' borderBottom={`2px solid ${borderColor}`}>
            {children}
          </HStack>
        );
    };


    React.useEffect(() => {
        const loadFilmAndUserRatings = async () => {
            const film = await getQuartileFilm(filmId);
            setQuartileFilmData(film);
            if (loggedIn) {
                const rating = await getUserRating({
                    filmTmdbId: filmId, user_id: userId
                });
                if (rating) {
                    setHasUserRated(true);
                    setQRating(rating.filmQRating);
                    setPlotRating(rating.filmPlotRating);
                    setActingRating(rating.filmActingRating);
                    setCinematographyRating(rating.filmCinematographyRating);
                    setNoveltyRating(rating.filmNoveltyRating);
                    setEndingRating(rating.filmEndingRating);
                }
            }
            setIsLoading(false);
        }
        loadFilmAndUserRatings();
    }, []);

    const handleRateFilm = async () => {
        try {
             const qRating = (plotRating + actingRating + cinematographyRating + noveltyRating + endingRating) / 2;
             setQRating(qRating);
            // debugger;
            await rateFilm({
                user_id: userId,
                filmTmdbId: filmId,
                filmTitle: title,
                filmYear: trimmedYear,
                filmQRating: qRating,
                filmPlotRating: plotRating,
                filmActingRating: actingRating,
                filmCinematographyRating: cinematographyRating,
                filmNoveltyRating: noveltyRating,
                filmEndingRating: endingRating,
                tmdbImagePath: imagePath,
            });
            const film = await getQuartileFilm(filmId);
            // TODO: Handle if film data is null, render no averages
            setQuartileFilmData(film);
            setHasUserRated(true);
            toast({
                title: 'Rating Submitted',
                description: "You've rated " + title + " a  " + qRating,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (e) {
            console.log('ERROR rating film in rateFilmScene: ', e);
        }
    }

    const isRatingDisabled = () => {
        return (
            plotRating === 0,
            actingRating === 0,
            cinematographyRating === 0,
            noveltyRating === 0,
            endingRating === 0
            // every rating has to be bigger than 0
        );
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <Button
                    letterSpacing='wider'
                    border='4px'
                    borderRadius={'14'}
                    borderColor={'qPurple.400'}
                    size ='lg'
                    fontFamily="buttonFont"
                    onClick={() => {setPage('LOGIN')
                }}>
                    Login to Rate
                </Button>

            );
        } else {
            return (
                    <Button
                        letterSpacing='wider'
                        border='4px'
                        borderRadius={'14'}
                        borderColor={'qPurple.400'}
                        size ='lg'
                        disabled={isRatingDisabled()}
                        onClick={() => {
                            handleRateFilm();
                        }}
                    >
                        {hasUserRated ? 'Update your rating' : 'Rate Film'}
                    </Button>
            );
        }
    }


    const renderRateTitle = (loggedIn) => {
        if (!isMobile) {
            if (!loggedIn) {
                return (
                    <Heading letterSpacing='wider' p='2' fontFamily={'buttonFont'} size={'2xl'}>
                        Rate
                    </Heading>

                );
            } else {
                return (
                    <Heading letterSpacing='wider' p='2' fontFamily={'buttonFont'} size={'2xl'}>
                        My Rating - {qRating}
                    </Heading>
                );
            }
        } else {
            if (!loggedIn) {
                return (
                    <Box width='90%' textAlign={'left'}>
                        <Heading letterSpacing='wider' pt='2' fontFamily={'buttonFont'} size={'3xl'}>
                            Rate
                        </Heading>
                    </Box>

                );
            } else {
                return (
                    <Box width='90%' textAlign={'left'}>
                        <Heading letterSpacing='wider' pt='2' fontFamily={'buttonFont'} size={'3xl'}>
                            My Rating
                        </Heading>
                    </Box>
                    
                );
            }
        }

    }

    const handleBackClick = () => {
        if (isFeaturedFilm) {
            setScene('HOME');
            return;
        }
        setScene('SEARCH_RESULTS');
    }

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>

         {!isMobile ? (
                <> 
                {/* can use layer styles for the Hstack or box to deal with theming changes, it can accept color or text color, borders, bg & opacity values */}
                <CustomHStack>
                
                    <HStack marginBottom={'-50px'} width={'100%'} >

                    <Stack direction={'row'} paddingLeft='30px' paddingTop='320px' color='gray.400' width='100%' spacing='0' alignItems="flex-start">
                        {/* <VStack spacing ='4' color='gray.400' alignItems="flex-start">
                            <Text>4</Text>
                            <Text>3</Text>
                            <Text>2</Text>
                            <Text>1</Text>
                        </VStack>
                        <VStack width='10%'>
                            <Box width='3px' margin='6px' height='140px' backgroundImage='linear-gradient(white ,gray, black)' >
                            </Box>
                        </VStack>
                        <VStack spacing ='4' color='gray.400' alignItems="flex-start">
                            <Text> Well Above Average</Text>
                            <Text> Above Average</Text>
                            <Text> Below Average</Text>
                            <Text> Well Below Average</Text>
                        </VStack> */}
                    </Stack>
                    
                    <Stack direction='column' width='140%' height='500px' alignItems={'end'} justify={'space-between'}  paddingRight={'4'} paddingTop={'3'}  >
                        <VStack alignItems={'end'}>
                            <Heading letterSpacing='wide' textAlign={'right'} fontFamily={'categoryFont'}  size='2xl'>
                                {title}
                            </Heading>
                            {trimmedYear &&
                                <Heading letterSpacing='wide' fontFamily='categoryFont' size='lg'>
                                    {trimmedYear}
                                </Heading>}
                        </VStack>
                        <Spacer />
                        <Heading  margin={'-5px'} height='200px' fontFamily={'numberFont'} fontSize={'200px'} >
                            {quartileFilmData ? quartileFilmData.filmQRatingAverage?.toFixed(1) : '??'}
                            {/*{filmQRatingAverage}*/}
                        </Heading>
                    </Stack>

                    </HStack>
                    <VStack borderLeft= {useColorModeValue('solid black 2px', 'solid white 2px')} width={'100%'}>
                        <Box height = '500px'>
                            <Image
                                src = {fullImgPath}
                                alt="Movie Pic Placeholder"
                                height='100%'
                                width='100%'
                                objectFit='cover'
                            />
                        </Box>

                    </VStack>

                </CustomHStack>
                <Spacer></Spacer>
               
                <Button letterSpacing='wider' border="3px solid #65C8D0" fontFamily="buttonFont" onClick={() => {handleBackClick()}}>BACK BUTTON</Button>

                <HStack paddingTop ='30px' paddingBottom ='50px' width='100%' justifyContent='space-between' borderBottom={useColorModeValue('solid black 2px','solid white 2px')}>
                    {/* AVG PLOT Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>PLOT</Heading>
                            <Center
                                w='160px'
                                h='160px'
                                border='4px'
                                borderRadius={'14'}
                                borderColor={'qPurple.400'}
                            >
                                <VStack spacing='-4'>
                                    <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px' color={useColorModeValue('black', 'white')} >
                                        {quartileFilmData ? quartileFilmData.filmPlotRatingAverage : '?'}
                                    </Box>
                                    <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        {quartileFilmData ? 
                                            quartileFilmData.filmPlotRatingAverage === 4 ? "Well Above Average" :
                                            quartileFilmData.filmPlotRatingAverage === 3 ? "Above Average" :
                                            quartileFilmData.filmPlotRatingAverage === 2 ? "Below Average" :
                                            quartileFilmData.filmPlotRatingAverage === 1 ? "Well Below Average" :
                                            "Loading Rating" : "Not Yet Rated"}
                                    </Text>
                                </VStack>
                               
                            </Center>
                    </VStack>

                    {/*AVG Acting Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>
                            {isDocumentary ? 'NARRATION' : 'ACTING'} 
                            {/* <Text as="span" fontSize='12px' color="gray.400" fontStyle="italic">/ Narration</Text> */}
                        </Heading>
                            <Center
                                w='160px'
                                h='160px'
                                color='white'
                                border='4px'
                                borderRadius={'14'}
                                borderColor={'qPurple.400'}
                            >
                                <VStack spacing='-4'>
                                    <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px' color={useColorModeValue('black', 'white')}>
                                        {quartileFilmData ? quartileFilmData.filmActingRatingAverage : '?'}
                                    </Box>
                                    <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                            {quartileFilmData ? 
                                                quartileFilmData.filmActingRatingAverage === 4 ? "Well Above Average" :
                                                quartileFilmData.filmActingRatingAverage === 3 ? "Above Average" :
                                                quartileFilmData.filmActingRatingAverage === 2 ? "Below Average" :
                                                quartileFilmData.filmActingRatingAverage === 1 ? "Well Below Average" :
                                                "Loading Rating" : "Not Yet Rated"}
                                    </Text>
                                </VStack>
                                    
                            </Center>
                    </VStack>

                    {/*AVG Cinematography Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Cinematography</Heading>
                            <Center
                                w='160px'
                                h='160px'
                                color='white'
                                border='4px'
                                borderRadius={'14'}
                                borderColor={'qPurple.400'}
                            >
                                <VStack spacing='-4'>
                                    <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px' color={useColorModeValue('black', 'white')}>
                                        {quartileFilmData ? quartileFilmData.filmCinematographyRatingAverage : '?'}
                                    </Box>
                                    <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                            {quartileFilmData ? 
                                                quartileFilmData.filmCinematographyRatingAverage === 4 ? "Well Above Average" :
                                                quartileFilmData.filmCinematographyRatingAverage === 3 ? "Above Average" :
                                                quartileFilmData.filmCinematographyRatingAverage === 2 ? "Below Average" :
                                                quartileFilmData.filmCinematographyRatingAverage === 1 ? "Well Below Average" :
                                                "Loading Rating" : "Not Yet Rated"}
                                    </Text>
                                </VStack>
                            </Center>
                    </VStack>

                    {/*AVG Novelty*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Novelty</Heading>
                            <Center
                                w='160px'
                                h='160px'
                                color='white'
                                border='4px'
                                borderRadius={'14'}
                                borderColor={'qPurple.400'}
                            >
                                <VStack spacing='-4'>
                                    <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px' color={useColorModeValue('black', 'white')}>
                                        {quartileFilmData ? quartileFilmData.filmNoveltyRatingAverage : '?'}
                                    </Box>
                                    <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                            {quartileFilmData ? 
                                                quartileFilmData.filmNoveltyRatingAverage === 4 ? "Well Above Average" :
                                                quartileFilmData.filmNoveltyRatingAverage === 3 ? "Above Average" :
                                                quartileFilmData.filmNoveltyRatingAverage === 2 ? "Below Average" :
                                                quartileFilmData.filmNoveltyRatingAverage === 1 ? "Well Below Average" :
                                                "Loading Rating" : "Not Yet Rated"}
                                    </Text>
                                </VStack>
                            </Center>
                    </VStack>
                    {/*AVG Ending Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'lg'}>Ending</Heading>
                            <Center
                                w='160px'
                                h='160px'
                                color='white'
                                border='4px'
                                borderRadius={'14'}
                                borderColor={'qPurple.400'}
                            >
                                <VStack spacing='-4'>
                                    <Box as='span' fontWeight='bold' fontFamily='numberFont' fontSize='100px' color={useColorModeValue('black', 'white')}>
                                        {quartileFilmData ? quartileFilmData.filmEndingRatingAverage : '?'}
                                    </Box>
                                    <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                            {quartileFilmData ? 
                                                quartileFilmData.filmEndingRatingAverage === 4 ? "Well Above Average" :
                                                quartileFilmData.filmEndingRatingAverage === 3 ? "Above Average" :
                                                quartileFilmData.filmEndingRatingAverage === 2 ? "Below Average" :
                                                quartileFilmData.filmEndingRatingAverage === 1 ? "Well Below Average" :
                                                "Loading Rating" : "Not Yet Rated"}
                                    </Text>
                                </VStack>
                            </Center>
                    </VStack>
                </HStack>



                {renderRateTitle(loggedIn)}

                <HStack paddingTop={'30px'} width='100%' justifyContent='space-between' >
                    {/* TODO: Implement as button group? Also make this it's own component */}

                    {/*PLOT Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>PLOT</Heading>
                        <SimpleGrid  columns={2}>
                            <Tooltip hasArrow label="Well Above Average" placement='top-start' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={plotRating === 4 ? 'qPurple.500' : ''}
                                    onClick={() => {setPlotRating(4)}}
                                >
                                    4
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={plotRating === 3 ? 'qPurple.500' : ''}
                                    onClick={() => {setPlotRating(3)}}
                                >
                                    3
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Below Average" placement='bottom-start' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={plotRating === 2 ? 'qPurple.500' : ''}
                                    onClick={() => {setPlotRating(2)}}
                                >
                                    2
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={plotRating === 1 ? 'qPurple.500' : ''}
                                    onClick={() => {setPlotRating(1)}}
                                >
                                    1
                                </Button>
                            </Tooltip>
                            
                        </SimpleGrid>
                    </VStack>

                    {/*Acting Rating*/}
                    <VStack width='200px'>
                        <Heading  letterSpacing='wider' fontFamily='categoryFont' size={'md'}>
                            {isDocumentary ? 'NARRATION' : 'ACTING'}                         
                        </Heading>
                        <SimpleGrid  columns={2}>
                            <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={actingRating === 4 ? 'qPurple.500' : ''}
                                    onClick={() => {setActingRating(4)}}
                                >
                                    4
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={actingRating === 3 ? 'qPurple.500' : ''}
                                    onClick={() => {setActingRating(3)}}
                                >
                                    3
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={actingRating === 2 ? 'qPurple.500' : ''}
                                    onClick={() => {setActingRating(2)}}
                                >
                                    2
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={actingRating === 1 ? 'qPurple.500' : ''}
                                    onClick={() => {setActingRating(1)}}
                                >
                                    1
                                </Button>
                            </Tooltip>
                        </SimpleGrid>
                    </VStack>

                    {/*Cinematography Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>Cinematography</Heading>
                        <SimpleGrid  columns={2}>
                            <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={cinematographyRating === 4 ? 'qPurple.500' : ''}
                                    onClick={() => {setCinematographyRating(4)}}
                                >
                                    4
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={cinematographyRating === 3 ? 'qPurple.500' : ''}
                                    onClick={() => {setCinematographyRating(3)}}
                                >
                                    3
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={cinematographyRating === 2 ? 'qPurple.500' : ''}
                                    onClick={() => {setCinematographyRating(2)}}
                                >
                                    2
                                </Button>
                            </Tooltip>  
                            <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={cinematographyRating === 1 ? 'qPurple.500' : ''}
                                    onClick={() => {setCinematographyRating(1)}}
                                >
                                    1
                                </Button>
                            </Tooltip>
                        </SimpleGrid>
                    </VStack>

                    {/*Novelty*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>Novelty</Heading>
                        <SimpleGrid  columns={2}>
                            <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={noveltyRating === 4 ? 'qPurple.500' : ''}
                                    onClick={() => {setNoveltyRating(4)}}
                                >
                                    4
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={noveltyRating === 3 ? 'qPurple.500' : ''}
                                    onClick={() => {setNoveltyRating(3)}}
                                >
                                    3
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={noveltyRating === 2 ? 'qPurple.500' : ''}
                                    onClick={() => {setNoveltyRating(2)}}
                                >
                                    2
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Well Below Average" placement='bottom' bg='qTurquoise.500' fontSize='18px'>            
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={noveltyRating === 1 ? 'qPurple.500' : ''}
                                    onClick={() => {setNoveltyRating(1)}}
                                >
                                    1
                                </Button>
                            </Tooltip>
                        </SimpleGrid>
                    </VStack>
                    {/*Ending Rating*/}
                    <VStack width='200px'>
                        <Heading letterSpacing='wider' fontFamily='categoryFont' size={'md'}>Ending</Heading>
                        <SimpleGrid  columns={2}>
                        <Tooltip hasArrow label="Well Above Average" placement='top' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={endingRating === 4 ? 'qPurple.500' : ''}
                                    onClick={() => {setEndingRating(4)}}
                                >
                                    4
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Above Average" placement='top-end' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={endingRating === 3 ? 'qPurple.500' : ''}
                                    onClick={() => {setEndingRating(3)}}
                                >
                                    3
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Below Average" placement='bottom-end' bg='qTurquoise.500' fontSize='18px'>
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={endingRating === 2 ? 'qPurple.500' : ''}
                                    onClick={() => {setEndingRating(2)}}
                                >
                                    2
                                </Button>
                            </Tooltip>
                            <Tooltip hasArrow label="Well Below Average" placement='left' bg='qTurquoise.500' fontSize='18px'>            
                                <Button
                                    height='60px'
                                    width='60px'
                                    border='2px'
                                    background={endingRating === 1 ? 'qPurple.500' : ''}
                                    onClick={() => {setEndingRating(1)}}
                                >
                                    1
                                </Button>
                            </Tooltip>
                        </SimpleGrid>
                    </VStack>
                </HStack>

                <Box padding={'20px'}>
                    {renderLoginButton(loggedIn)}
                </Box>
                </>
                ) : (
                    // mobile design
                    <> 
                
                        <Spacer paddingTop='2'></Spacer>

                            {/* rate Mobile Film Card */}
                        <VStack  class= "rateMobileFilmCardVstack">
                                <Image
                                    src = {fullImgPath}
                                    alt="Movie Pic Placeholder"
                                    class = "rateMobileFilmCardFormat"
                                    style={{ border: `3px solid ${borderColor}` }}
                                />

                                <VStack class={"rateMobileQCardFormat"} style={{ border: `3px solid ${borderColor}`, backgroundColor: bgColor }}>
                                    <Heading  class="rateMobileQCardNumberFormat" justify-content={"flex-end"} >
                                        {quartileFilmData ? quartileFilmData.filmQRatingAverage?.toFixed(1) : '??'}
                                    </Heading>
                                    <Image
                                        src = "QLogoTest1.png"
                                        alt="q Logo "
                                        class = "rateMobileQCardIconFormat"
                                    />
                                </VStack>
                                <Heading class = "rateMobileQCardTitleFormat" >
                                    {title}
                                </Heading>
                                {trimmedYear && <Heading class ='rateMobileQCardYearFormat' >
                                    {trimmedYear}
                                </Heading>}
                        </VStack>

                
                        <Carousel
                        quartileFilmData ={quartileFilmData}
                        isDocumentary={isDocumentary}
                        />
                
                        <Spacer paddingTop='6'></Spacer>
        
                        <Button letterSpacing='wider' border="3px solid #65C8D0" fontFamily="buttonFont" onClick={() => {handleBackClick()}}>BACK BUTTON</Button>
                        
                        {/* <Spacer paddingTop='8'></Spacer> */}

                        <Text  fontFamily='paragraphFont' color='gray.500' fontSize='10px'>
                            Rate Below
                        </Text>

                        <Box borderTop="2px" marginTop='20px' width="100%" borderColor="gray.500" />
                        {renderRateTitle(loggedIn)}

                        <HStack width='90%' align='left'>
                            <Image
                                src = {fullImgPath}
                                alt="Movie Pic Placeholder"
                                width='60px'
                                height='60px'
                                backgroundSize='cover'
                                backgroundPosition={'center'}
                                border= '2px solid white'
                                objectFit='cover'
                                borderRadius='15%'
                            />
                            <VStack align='left' spacing='.6'>
                                <Heading class = "smRateMobileQCardTitleFormat" >
                                    {title}
                                </Heading>
                                {trimmedYear && <Heading class ='smRateMobileQCardYearFormat' >
                                    {trimmedYear}
                                </Heading>}
                            </VStack>
                        </HStack>
                            
                        <RateCarousel
                            qRating={qRating}
                            plotRating={plotRating}
                            actingRating={actingRating}
                            cinematographyRating={cinematographyRating}
                            noveltyRating={noveltyRating}
                            endingRating={endingRating}
                            setQRating={setQRating}
                            setPlotRating={setPlotRating}
                            setActingRating={setActingRating}
                            setCinematographyRating={setCinematographyRating}
                            setNoveltyRating={setNoveltyRating}
                            setEndingRating={setEndingRating}
                            isDocumentary={isDocumentary}
                        />

                        <VStack width='100%' pt='5' spacing='-4'>
                            <Text fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        Select A Number
                            </Text>

                            <HStack pt='6' width='80%' justify={'space-around'} align='top'>
                                <VStack>
                                    <Text mb='-1' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        4
                                    </Text>
                                    <Text fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        Well<br></br> Above <br></br>Average
                                    </Text>
                                </VStack>
                                <VStack>
                                    <Text mb='-1' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        3
                                    </Text>
                                    <Text fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        Above <br></br> Average
                                    </Text>
                                </VStack>
                                <Box borderRight="2px" height="70px" borderColor="gray.500" />
                                <VStack>
                                    <Text mb='-1' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        2
                                    </Text>
                                    <Text fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        Below <br></br> Average
                                    </Text>
                                </VStack>
                                <VStack>
                                    <Text mb='-1' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        1
                                    </Text>
                                    <Text fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                        Well <br></br>Below <br></br>Average
                                    </Text>
                                </VStack>
                            </HStack>
                        </VStack>
                          

                        <Spacer paddingTop='2'></Spacer>
                                            
                        <VStack  class={"myRateQCardFormat "} style={{ backgroundColor: `${bgColor}` }}
 >
                            <Heading  class="myRateQCardNumberFormat"  justify-content={"flex-end"} >
                            {/* {quartileFilmData ? quartileFilmData.filmQRatingAverage?.toFixed(1) : '??'} */}
                            {qRating ? qRating.toFixed(1): '??'}                            
                            </Heading>
                            <Image
                                src = "QLogoTest1.png"
                                alt="q Logo "
                                class = "myRateQCardIconFormat"
                            />
                        </VStack>

                        <Box padding={'20px'} pb='24'>
                            {renderLoginButton(loggedIn)}
                        </Box>
                    </>
                )}
        </>
    );
};

export default RateFilmScene;