

export const registerApi = async ({ email, password }) => {
    try {
        const resp = await fetch('api/auth/register', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' },
        });
        
        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling register: ', err);
        throw err;
    }
}

export const verifyApi = async ({
    token
  }) => {
    try {
     const resp = await fetch('api/auth/verify', {
        method: 'POST',
        body: JSON.stringify({ token }),
        headers: { "Content-Type": "application/json" },
        
      });
  
      if (!resp.ok) {
        const respJson = await resp.json();
        throw new Error(respJson.message);
      }

      return resp.json();
    } catch (err) {
        console.log('ERROR calling verify: ', err);
        throw err;
    }
  };
  
export const loginApi = async ({ email, password }) => {
    try {
        const resp = await fetch('api/auth/login', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' },
        });
        
        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling login: ', err);
        throw err;
    }
}

//Kai Attempt at LOGOUT
export const logoutApi = async () => {
    try {
        const resp = await fetch('api/auth/logout', {
            method: 'POST',
            //passing email so we can display email in the logged out toast pop up
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.status;
    } catch (err) {
        console.log('ERROR calling logout: ', err);
        throw new Error('ERROR calling logout: ', err);
    }
}

export const searchTmdbFilms = async (
    keyword
) => {
    try {
        debugger;
        const resp = await fetch(`api/tmdb/films/${keyword}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Search TMDB Films: ', err);
        throw new Error('ERROR calling Search TMDB Films: ', err);
    }
}

export const searchTmdbFilmById = async (
    featureFilmId
) => {
    try {
        const resp = await fetch(`api/tmdb/film/${featureFilmId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Search TMDB Films: ', err);
        throw new Error('ERROR calling Search TMDB Films: ', err);
    }
}

export const rateFilm = async ({
    user_id,
    filmTmdbId,
    filmTitle,
    filmYear,
    filmQRating,
    filmPlotRating,
    filmActingRating,
    filmCinematographyRating,
    filmNoveltyRating,
    filmEndingRating,
    tmdbImagePath
}) => {
    try {
        const resp = await fetch('api/ratings/rate', {
            method: 'POST',
            body: JSON.stringify({ 
                user_id,
                filmTmdbId,
                filmTitle,
                filmYear,
                filmQRating,
                filmPlotRating,
                filmActingRating,
                filmCinematographyRating,
                filmNoveltyRating,
                filmEndingRating,
                tmdbImagePath
            }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.status;
    } catch (err) {
        console.log('ERROR calling rate film: ', err);
        throw new Error('ERROR calling rate film: ', err);
    }
}

export const getUserRating = async ({
    filmTmdbId,
    user_id,
}) => {
    try {
        const resp = await fetch('api/ratings/rate/getUserRating', {
            method: 'POST',
            body: JSON.stringify({
                filmTmdbId,
                user_id,
            }),
            headers: {'Content-Type': 'application/json'},
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Get User Rating: ', err);
        throw new Error('ERROR calling Get User Rating: ', err);
    }
}

export const getQuartileFilm = async (
    filmTmdbId
) => {
    try {
        const resp = await fetch(`api/films/${filmTmdbId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Get Quartile Film: ', err);
        throw new Error('ERROR calling Get Quartile Film: ', err);
    }
}

export const getTopFilmsByCategory = async (categories) => {
    try {
        const resp = await fetch('api/films/topFilmsByCategory', {
            method: 'POST',
            body: JSON.stringify({
                categories: categories
            }),
            headers: {'Content-Type': 'application/json'}
        });
        return resp.json();
    } catch (err) {
        console.log('ERROR calling get top films by category', err);
    }
}
