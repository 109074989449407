import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    Box,
    VStack,
    Link,
    Stack,
    HStack,
    Text,
    Center,
    BeatLoader,
    useColorModeValue,
    Heading, 
    Image
} from '@chakra-ui/react';
import logo from '../logo.svg';
import nodeJs from '../nodeJs.svg';
import mongoDb from '../mongodb.svg';
import chakra from '../chakra.svg';
import AltNav from "./AltNav";
import MobileAltNav from './MobileAltNav';

const AboutPage = (props) => {
    const {
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [scene, setScene] = useState('HOME');
    const [searchFilmsResponse, setSearchFilmsResponse] = useState([]);
    const [selectedFilm, setSelectedFilm] = useState([]);
    const isMobile = useMedia('(max-width: 767px)');


    return (
        <>
                <VStack  width ="100%" align='stretch' >
                    <Center >
                    {isMobile ? (
                            <MobileAltNav 
                                setScene={setScene}
                                setSearchFilmsResponse={setSearchFilmsResponse}
                                setPage={setPage}
                                page={page}
                                setLoggedIn={setLoggedIn}
                                loggedIn={loggedIn}
                                handleLogout={handleLogout}
                                customSwitchChecked={customSwitchChecked}
                                setCustomSwitchChecked={setCustomSwitchChecked}
                                colorMode={colorMode}
                                toggleColorMode={toggleColorMode}
                            />
                        ) : (
                            <AltNav 
                                setScene={setScene}
                                setSearchFilmsResponse={setSearchFilmsResponse}
                                setPage={setPage}
                                page={page}
                                setLoggedIn={setLoggedIn}
                                loggedIn={loggedIn}
                                handleLogout={handleLogout}
                                customSwitchChecked={customSwitchChecked}
                                setCustomSwitchChecked={setCustomSwitchChecked}
                                colorMode={colorMode}
                                toggleColorMode={toggleColorMode}
                            />
                        )}
                    </Center>
                </VStack>

                {!isMobile ? (
                <> 
                <VStack class={'aboutPageFormat'}>
                    <Stack margin='10px' p='20px' paddingTop={'40px'} spacing={10} justify='space-around' direction='row'>
                        <VStack  width ='40%'  spacing='3' >

                            <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='3xl' >
                                Quartile
                            </Heading>

                            <div  class='aboutOverline' align='center'>
                                <Text fontFamily= 'paragraphFont' class =' blackText widthControl'  >
                                    Current film rating systems on the market are ambiguous.

                                    The Quartile rating system is designed to leverage mathematics to produce accurate and non-ambiguous values to rate film. The Quartile system takes into account the films 5
                                    most important elements, each of these categories receives a value from 1 - 4. The final
                                    rating is produced by the summation of all categories then divided by 2, which produces a
                                    potential score range from 2.5 - 10.
                                </Text>

                            </div>

                            <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='3xl' >
                                Rating Scale
                            </Heading>
                            <div  className='aboutOverline' align='center'  >
                                <Box  width={'80%'} >
                                    <HStack p={'6'} spacing='30px'   >
                                        <Text class='aboutNumberFormat'  >
                                            4
                                        </Text>
                                        <Text color='black' fontSize='2xl' >
                                            Well Above Average
                                        </Text>
                                    </HStack>
                                    <HStack  p={'6'} spacing='30px'  >
                                        <Text class='aboutNumberFormat'>
                                            3
                                        </Text>
                                        <Text color='black' fontSize='2xl' >
                                            Above Average
                                        </Text>
                                    </HStack>
                                    <HStack p={'6'} spacing='30px'>
                                        <Text class='aboutNumberFormat'>
                                            2
                                        </Text>
                                        <Text color='black' fontSize='2xl'>
                                            Below Average
                                        </Text>
                                    </HStack>
                                    <HStack p={'6'} spacing='30px'>
                                        <Text class='aboutNumberFormat'>
                                            1
                                        </Text>
                                        <Text color='black' fontSize='2xl'>
                                            Well Below Average
                                        </Text>
                                    </HStack>
                                </Box>
                            </div>
                        </VStack>
                        <VStack   spacing='3' width='40%' >
                            <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size={'3xl'}>
                                Categories
                            </Heading>
                            <div  class='aboutOverline'>
                                <Box p={'6'}>
                                    <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='xl'>
                                        Plot
                                    </Heading>
                                    <Text class ='textAlignmentLeft blackText' fontFamily= 'paragraphFont' >
                                        Also called storyline. The plan, scheme, or main story of a literary or
                                        dramatic work, as a play, novel, or short story.
                                    </Text>
                                </Box>

                                <Box p={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'xl'}>
                                        Acting <Text as="span" color="white">/ Narration</Text>
                                    </Heading>
                                    <Text class ='textAlignmentLeft blackText' fontFamily= 'paragraphFont'>
                                        The art, profession, or activity of those who perform in stage plays,
                                        motion pictures, etc. <Text as="span" color="white" fontStyle="italic">For documentary films, this category becomes Narration.</Text>
                                    </Text>
                                </Box>

                                <Box p={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'xl'}>
                                        Cinematography
                                    </Heading>
                                    <Text class ='textAlignmentLeft blackText' fontFamily= 'paragraphFont' >
                                        The artistic vision, tone, look, and feel of a video production. <Text as="span" color="white" fontStyle="italic">This category includes Soundtrack.</Text>
                                    </Text>
                                </Box>

                                <Box p={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'xl'} >
                                        Novelty
                                    </Heading>
                                    <Text class ='textAlignmentLeft blackText' fontFamily= 'paragraphFont'>
                                        Freshness of aspect, design, or style.
                                    </Text>
                                </Box>

                                <Box p={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'xl'}>
                                        Ending
                                    </Heading>
                                    <Text class ='textAlignmentLeft blackText' fontFamily= 'paragraphFont'>
                                        The final or concluding part of storyline; conclusion.
                                    </Text>
                                </Box>
                            </div>
                        </VStack>
                    </Stack>

                    <VStack  m='10px' margin p='20px' >

                        <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='3xl'>
                            Formula
                        </Heading>
                        <Box   className='aboutOverline' width={'40%'} textAlign='left'>
                        </Box>
                    </VStack>

                    <HStack margin='20px' p='20px' paddingTop={'40px'} spacing={-80} justify='space-around' >

                        <VStack >
                            <Stack p={5} spacing={'10'} align={'end'} justify='space-around' direction={'row'}>

                                <VStack spacing={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='md'>
                                        Plot
                                    </Heading>
                                    <Text  class='aboutNumberFormat'>
                                        3
                                    </Text>
                                </VStack>

                                <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='3xl'>
                                    +
                                </Heading>

                                <VStack spacing={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='md'>
                                        Acting
                                    </Heading>
                                    <Text class='aboutNumberFormat'>
                                        4
                                    </Text>
                                </VStack>

                                <Heading color='black' fontFamily={'categoryFont'}  size='3xl'>
                                    +
                                </Heading>

                                <VStack spacing={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='md'>
                                        Cinematography
                                    </Heading>
                                    <Text class='aboutNumberFormat'>
                                        3
                                    </Text>
                                </VStack>

                                <Heading color='black' fontFamily={'categoryFont'} size='3xl'>
                                    +
                                </Heading>

                                <VStack spacing={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='md'>
                                        Novelty
                                    </Heading>
                                    <Text class='aboutNumberFormat'>
                                        2
                                    </Text>
                                </VStack>

                                <Heading color='black' fontFamily={'categoryFont'} size='3xl'>
                                    +
                                </Heading>

                                <VStack spacing={'6'}>
                                    <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='md'>
                                        Ending
                                    </Heading>
                                    <Text class='aboutNumberFormat'>
                                        1
                                    </Text>
                                </VStack>
                            </Stack>
                            <div  class='aboutFormulaOverline'  >
                            </div>
                            <Heading color='black' fontFamily='numberFont' size={'4xl'}>
                                2
                            </Heading>

                        </VStack>
                        <Heading color='black' fontFamily={'categoryFont'} size='4xl'>
                            &ensp;=
                        </Heading>
                        <Heading color='black' fontFamily={'numberFont'} size={'4xl'}>
                            &ensp; 6.5
                        </Heading>

                    </HStack>
                    <HStack p='50px' paddingBottom={'100px'}  justify={'center'} spacing={'10'}>
                        <VStack  class={"aboutQCardFormat "} >
                            <Heading  class="aboutQCardNumberFormat"  justify-content={"flex-end"} >
                                6.5
                            </Heading>
                            <Image
                                src = "QLogoTest1.png"
                                alt="q Logo "
                                class = "aboutQCardIconFormat"
                            />
                        </VStack>
                        <HStack>
                        <VStack alignItems={'start'} m={'0'}>
                            <Heading color='black' fontFamily='numberFont' letterSpacing={'wider'} size='md'>
                                3
                            </Heading>
                            <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='md'>
                                4
                            </Heading>
                            <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='md'>
                                3
                            </Heading>
                            <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='md'>
                                2
                            </Heading>
                            <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='md'>
                                1
                            </Heading>
                        </VStack>
                        <VStack alignItems={'start'}>
                            <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='md'>
                                = Plot
                            </Heading>
                            <Heading color='black'  fontFamily='categoryFont' letterSpacing={'wider'} size='md'>
                                = Acting
                            </Heading>
                            <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='md'>
                                = Cinematography
                            </Heading>
                            <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='md'>
                                = Novelty
                            </Heading>
                            <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='md'>
                            = Ending
                            </Heading>
                        </VStack>
                        </HStack>

                    </HStack>

                </VStack>

                <VStack  class={'aboutPageFormat'} >
                    <VStack  m='10px'  p='20px' paddingTop='40px' >
                        <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='3xl'>
                            Company
                        </Heading>
                        <Box   className='aboutOverline' width={'40%'} textAlign='left'>
                        </Box>
                        <Box  width={'60%'} textAlign='left'>
                            <Text color='black' marginTop={'15px'} fontFamily= 'paragraphFont'>
                                Kai & Andrew were college roomates and created Quartile procrastinating during one of their
                                finals weeks. After commonly spending hours trying to find good films to watch they decided
                                to come up with their own ranking system that was more systematic and would have higher quality
                                control. They both currently reside in San Diego, CA.
                            </Text>

                            <Text color='black' marginTop={'15px'}>
                                Quartile never uses&ensp;
                                <Link href='https://www.deceptive.design/' isExternal>
                                    <Text as="u" color='gray.500'>dark patterns</Text>
                                </Link>
                            </Text>
                            <Text color='black' marginTop={'15px'}>
                                We are still in Alpha, but are grinding to get to Beta.
                            </Text>

                        </Box>
                        <Stack margin='10px' p='50px' spacing='300px' direction='row'>

                            <VStack  width='50%'>
                                <Image
                                    src = "KaiPic.jpeg"
                                    alt="Movie Pic Placeholder"
                                    class = "aboutProfileCardFormat "
                                />
                                <Box  width='100%'  textAlign={'left'}>
                                    <VStack paddingTop={5} spacing='4' align={'left'}>
                                        <Heading color='black'  fontFamily="categoryFont" letterSpacing={'wider'} size="lg" >
                                            Kai Rogers
                                        </Heading>
                                        <Heading color='black' fontFamily="paragraphFont" letterSpacing={'wider'} size="xs">
                                            Product Designer, <br />
                                            Software Engineer
                                        </Heading>
                                    </VStack>
                                </Box>

                            </VStack>
                            <VStack   width='50%'>
                                <Image
                                    src = "GoughPixelPic.jpg"
                                    alt="Movie Pic Placeholder"
                                    class = "aboutProfileCardFormat "
                                />
                                <Box  width='100%'  textAlign={'left'}>
                                    <VStack paddingTop={5} spacing='4' align={'left'}>
                                        <Heading color='black' fontFamily="categoryFont" letterSpacing={'wider'} size="lg" >
                                            Andrew Gough
                                        </Heading>
                                        <Heading color='black' fontFamily="paragraphFont" letterSpacing={'wider'} size="xs">
                                            Software Engineer
                                        </Heading>
                                    </VStack>
                                </Box>
                            </VStack>

                        </Stack>
                        <Heading color='black' fontFamily="buttonFont" letterSpacing={'widest'} size="md" p={'6'} >
                            info@quartilefilm.io
                        </Heading>
                    </VStack>
                </VStack>


                <Heading textAlign='left' paddingLeft = '300' marginBottom='10' marginTop='20' fontFamily='paragraphFont' size={'2xl'}>
                    Dev Tech Stack
                </Heading>

                <HStack  justify={'space-around'} paddingBottom='50px'>
                    <VStack  p={'10'}>
                        <Box>
                            <img src={nodeJs} className="node-logo" alt="logo" />
                        </Box>
                    </VStack>

                    <VStack  p={''}>
                        <Box>
                            <img src={logo} className="App-logo" alt="logo" />
                        </Box>
                    </VStack>

                    <VStack  p={'10'}>
                        <Box>
                            <img src={mongoDb} className="mongo-logo" alt="logo" />
                        </Box>
                    </VStack>

                    <VStack  p={'16'}>
                        <Box>
                            <img src={chakra} className="chakra-logo" alt="logo" />
                        </Box>
                    </VStack>
                </HStack>

                <Center paddingBottom='100px' paddingTop ='100px'>
                    <Text fontSize='sm'>
                        This product uses the TMDB API but is not endorsed or certified by
                    </Text>
                    <Image
                        marginLeft = '3'
                        src = 'tmdbLong.svg'
                        alt="Movie Pic Placeholder"
                        id='tmdbIcon'
                    />
                </Center> 
                </>
                ) : (
                    // mobile design
                    <>
                   
                    <Heading fontSize='10px' mb='-3' pr='5' letterSpacing={'wider'} fontFamily={"paragraphFont"} textAlign={'right'} >
                    About
                    </Heading>
                    
                    <VStack class={'aboutPageFormat'}>
                        <VStack margin='5px' paddingTop='5' spacing={8} justify='space-around'>
                            <VStack width ='90%'>
                                <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='lg' >
                                        Quartile
                                    </Heading>
                                    <div  class='aboutOverline'>
                                        <Text class ='textAlignment blackText' fontFamily= 'paragraphFont'>
                                            Current film rating systems on the market are ambiguous.

                                            The Quartile rating system is designed to leverage mathematics to produce accurate and non-ambiguous values to rate film. The Quartile system takes into account the films 5
                                            most important elements, each of these categories receives a value from 1 - 4. The final
                                            rating is produced by the summation of all categories then divided by 2, which produces a
                                            potential score range from 2.5 - 10.
                                        </Text>
                                    </div>
                            </VStack>

                            <VStack  spacing='2' width='90%' >
                                <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size={'lg'}>
                                    Categories
                                </Heading>
                                <div  class='aboutOverline'>
                                    <Box pt={'4'}>
                                        <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='md'>
                                            Plot
                                        </Heading>
                                        <Text class ='blackText' fontFamily= 'paragraphFont' >
                                            Also called storyline. The plan, scheme, or main story of a literary or
                                            dramatic work, as a play, novel, or short story.
                                        </Text>
                                    </Box>

                                    <Box pt={'6'}>
                                        <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'md'}>
                                            Acting <Text as="span" color="white">/ Narration</Text>
                                        </Heading>
                                        <Text class =' blackText' fontFamily= 'paragraphFont'>
                                            The art, profession, or activity of those who perform in stage plays,
                                            motion pictures, etc. <Text as="span" color="white" fontStyle="italic">For documentary films, this category becomes Narration.</Text>
                                        </Text>
                                    </Box>

                                    <Box pt={'6'}>
                                        <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'md'}>
                                            Cinematography
                                        </Heading>
                                        <Text class ='blackText' fontFamily= 'paragraphFont' >
                                            The artistic vision, tone, look, and feel of a video production. <Text as="span" color="white" fontStyle="italic">This category includes Soundtrack.</Text>
                                        </Text>
                                    </Box>

                                    <Box pt={'6'}>
                                        <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'md'} >
                                            Novelty
                                        </Heading>
                                        <Text class ='blackText' fontFamily= 'paragraphFont'>
                                            Freshness of aspect, design, or style.
                                        </Text>
                                    </Box>

                                    <Box pt={'6'}>
                                        <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size={'md'}>
                                            Ending
                                        </Heading>
                                        <Text class ='blackText' fontFamily= 'paragraphFont'>
                                            The final or concluding part of storyline; conclusion.
                                        </Text>
                                    </Box>
                                </div>
                            </VStack>

                            <VStack  width ='90%' >
                                <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='lg' >
                                    Rating Scale
                                </Heading>
                                <div  className='aboutOverline' align='center'  >
                                    <Box pt='4' pl='3' width={'100%'} >
                                        <HStack spacing='10px' >
                                            <Box w='40px' h='40px' border='3px solid white' borderRadius='20%'>
                                                <Text fontFamily='Orborn' fontSize='20px' pt='1' pr='.5' color='black' >
                                                    4
                                                </Text>
                                            </Box>
                                            <Text color='black' fontSize='lg' >
                                                Well Above Average
                                            </Text>
                                        </HStack>
                                        <HStack pt='5' spacing='10px'  >
                                            <Box w='40px' h='40px' border='3px solid white' borderRadius={'20%'}>
                                                <Text fontFamily='Orborn' fontSize={'20px'} pt='1' pr='.5' color='black'>
                                                    3
                                                </Text>
                                                </Box>
                                            <Text paddingRight={'4'}  color='black' fontSize='lg' >
                                                Above Average
                                            </Text>
                                        </HStack>
                                        <HStack pt={'5'} spacing='10px'>
                                            <Box w='40px' h='40px' border='3px solid white' borderRadius={'20%'}>
                                                <Text fontFamily='Orborn' fontSize={'20px'} pt='1' pr='.5' color='black'>
                                                    2
                                                </Text>
                                            </Box>
                                            <Text color='black' fontSize='lg'>
                                                Below Average
                                            </Text>
                                        </HStack>
                                        <HStack pt={'5'} spacing='10px'>
                                            <Box w='40px' h='40px' border='3px solid white' borderRadius={'20%'}>
                                                <Text fontFamily='Orborn' fontSize={'20px'} pt='1' pr='1' color='black'>
                                                    1
                                                </Text>
                                            </Box>
                                            <Text color='black' fontSize='lg'>
                                                Well Below Average
                                            </Text>
                                        </HStack>
                                    </Box>
                                </div>
                            </VStack>

                            <VStack width='90%' >
                                <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='lg' >
                                    Formula 
                                </Heading>
                                <div  className='aboutOverline' align='center'  >
                                </div>
                            </VStack>

                            <HStack>
                                <VStack>
                                    <Stack spacing='1' direction={'row'}>

                                        <VStack spacing={'2'}>
                                            <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} fontSize='8px'>
                                                Plot
                                            </Heading>
                                            <Text  class='aboutFormulaNumberFormat'>
                                                3
                                            </Text>
                                        </VStack>

                                        <Heading pt='2.5' paddingInline='2' color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} size='xl'>
                                            +
                                        </Heading>

                                        <VStack spacing={'2'}>
                                            <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} fontSize='8px'>
                                                Acting
                                            </Heading>
                                            <Text class='aboutFormulaNumberFormat'>
                                                4
                                            </Text>
                                        </VStack>

                                        <Heading pt='2.5' pl='2' color='black' fontFamily={'categoryFont'}  size='xl'>
                                            +
                                        </Heading>

                                        <VStack spacing={'2'}>
                                            <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} fontSize='8px'>
                                                Cinematography
                                            </Heading>
                                            <Text class='aboutFormulaNumberFormat'>
                                                3
                                            </Text>
                                        </VStack>

                                        <Heading pt='2.5' pr='2' color='black' fontFamily={'categoryFont'} size='xl'>
                                            +
                                        </Heading>

                                        <VStack spacing={'2'}>
                                            <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} fontSize='8px'>
                                                Novelty
                                            </Heading>
                                            <Text class='aboutFormulaNumberFormat'>
                                                2
                                            </Text>
                                        </VStack>

                                        <Heading pt='2.5' paddingInline='2' color='black' fontFamily={'categoryFont'} size='xl'>
                                            +
                                        </Heading>

                                        <VStack spacing={'2'}>
                                            <Heading color='black' fontFamily={'categoryFont'} letterSpacing={'wider'} fontSize='8px'>
                                                Ending
                                            </Heading>
                                            <Text class='aboutFormulaNumberFormat'>
                                                1
                                            </Text>
                                        </VStack>
                                    </Stack>
                                    <div  class='aboutFormulaOverline' >
                                    <Heading color='black' fontFamily='numberFont' size={'xl'}>
                                        2
                                    </Heading>
                                    </div>
                                   
                                </VStack>
                            </HStack>

                            {/* <Heading pt='-6' color='black' fontWeight={'thinner'} fontFamily={'categoryFont'} size='4xl'>
                                        =
                            </Heading> */}
                            
                    </VStack>

                    <HStack pt='20px' paddingBottom={'50px'} justify={'center'} spacing={'6'}>
                        <VStack  class={"aboutQCardFormat "} >
                            <Heading  class="aboutQCardNumberFormat"  justify-content={"flex-end"} >
                                6.5
                            </Heading>
                            <Image
                                src = "QLogoTest1.png"
                                alt="q Logo "
                                class = "aboutQCardIconFormat"
                            />
                        </VStack>
                        <HStack >
                            <VStack alignItems={'start'} >
                                <Heading color='black' fontFamily='numberFont' letterSpacing={'wider'} size='sm'>
                                    3
                                </Heading>
                                <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='sm'>
                                    4
                                </Heading>
                                <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='sm'>
                                    3
                                </Heading>
                                <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='sm'>
                                    2
                                </Heading>
                                <Heading  color='black' fontFamily='numberFont' letterSpacing={'wider'} size='sm'>
                                    1
                                </Heading>
                            </VStack>
                            <VStack alignItems={'start'} spacing='2.5'>
                                <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='xs'>
                                    = Plot
                                </Heading>
                                <Heading color='black'  fontFamily='categoryFont' letterSpacing={'wider'} size='xs'>
                                    = Acting
                                </Heading>
                                <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='xs'>
                                    = Cinematography
                                </Heading>
                                <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='xs'>
                                    = Novelty
                                </Heading>
                                <Heading  color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='xs'>
                                    = Ending
                                </Heading>
                            </VStack>
                        </HStack>
                    </HStack>

                </VStack>

                <VStack  class={'aboutPageFormat'} >
                    <VStack  m='10px'  pt='20px' >
                        <Heading color='black' fontFamily='categoryFont' letterSpacing={'wider'} size='lg'>
                            Company
                        </Heading>
                        <Box   className='aboutOverline' width={'90%'} textAlign='left'>
                        </Box>
                        <Box  width={'90%'} textAlign='center'>
                            <Text color='black' marginTop={'15px'} fontFamily= 'paragraphFont' class='blackText'>
                                Kai & Andrew were college roomates and created Quartile procrastinating during one of their
                                finals weeks. After commonly spending hours trying to find good films to watch they decided
                                to come up with their own ranking system that was more systematic and would have higher quality
                                control. They both currently reside in San Diego, CA.
                            </Text>

                            <Text color='black' marginTop={'15px'} class='blackText'>
                                <br></br>
                                Quartile never uses &ensp;
                                <Link href='https://www.deceptive.design/' isExternal>
                                    <Text as="u" color='gray.500'>dark patterns</Text>
                                </Link>
                            </Text>
                            <Text color='black' marginTop={'15px'} class='blackText' >
                                <br></br>
                                We are still in Alpha, but are grinding to get to Beta.
                            </Text>

                        </Box>
                        <VStack pt='20px' spacing='10'>
                            <VStack  width='100%'>
                                <Image
                                    src = "KaiPic.jpeg"
                                    alt="Movie Pic Placeholder"
                                    class = "aboutProfileCardFormat "
                                />
                                <Box  width='100%'  textAlign={'left'}>
                                    <VStack paddingTop={1} spacing='2' align={'left'}>
                                        <Heading color='black'  fontFamily="categoryFont" letterSpacing={'wider'} size="md" >
                                            Kai Rogers
                                        </Heading>
                                        <Heading color='black' fontFamily="paragraphFont" letterSpacing={'wider'} fontSize='10px'>
                                            Product Designer, <br />
                                            Software Engineer
                                        </Heading>
                                    </VStack>
                                </Box>

                            </VStack>
                            <VStack   width='100%'>
                                <Image
                                    src = "GoughPixelPic.jpg"
                                    alt="Movie Pic Placeholder"
                                    class = "aboutProfileCardFormat "
                                />
                                <Box  width='100%'  textAlign={'right'}>
                                    <VStack paddingTop={1} spacing='2' align={'right'}>
                                        <Heading color='black' fontFamily="categoryFont" letterSpacing={'wider'} size="md" >
                                            Andrew Gough
                                        </Heading>
                                        <Heading color='black' fontFamily="paragraphFont" letterSpacing={'wider'} fontSize="10px">
                                            Software Engineer
                                        </Heading>
                                    </VStack>
                                </Box>
                            </VStack>
                        </VStack>
                        <Heading color='black' fontFamily="buttonFont" letterSpacing={'widest'} size="sm" p={'10'} >
                            info@quartilefilm.io
                        </Heading>
                    </VStack>
                </VStack>

                 <Center>
                 <Heading marginBottom='5' fontFamily='paragraphFont' size={'xl'}>
                    Dev Tech Stack
                 </Heading>
                 </Center>
                
                <VStack  justify={'space-around'} paddingBottom='5px'>
                    <HStack pl='8'>
                        <VStack  p={'2'}>
                            <Box>
                                <img src={nodeJs} className="node-logo" alt="logo" />
                            </Box>
                        </VStack>

                        <VStack  p={''}>
                            <Box>
                                <img src={logo} className="App-logo" alt="logo" />
                            </Box>
                        </VStack>
                    </HStack>
                    <HStack pl='4'>
                        <VStack  p='' pr='8'>
                            <Box>
                                <img src={mongoDb} className="mongo-logo" alt="logo" />
                            </Box>
                        </VStack>

                        <VStack  p={'4'} pr='6' pt='5'>
                            <Box>
                                <img src={chakra} className="chakra-logo" alt="logo" />
                            </Box>
                        </VStack>
                    </HStack>
                </VStack>

                <VStack padding='50px' paddingBottom ='20'>
                    <Text fontSize='sm'>
                        This product uses the TMDB API but is not endorsed or certified by
                    </Text>
                    <Image
                        marginLeft = '3'
                        src = 'tmdbLong.svg'
                        alt="Movie Pic Placeholder"
                        id='tmdbIcon'
                    />
                </VStack> 

                    </>
                 )} 


        </>


    );
};


export default AboutPage;