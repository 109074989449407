import React, { Component } from "react";
import {
    HStack,
    VStack,
    Heading,
    Text,
    Image,
    Button,
    useColorModeValue,
    Center,
} from '@chakra-ui/react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrowWhite from '../rightArrowWhite.png'
import leftArrowWhite from '../leftArrowWhite.png'
import rightArrowBlack from '../rightArrowBlack.png'
import leftArrowBlack from '../leftArrowBlack.png'

const PrevArrow = ({ onClick, currentSlide }) => {
    if (currentSlide === 0) {
      return null;
    }
    
    const prevArrowImage = useColorModeValue(leftArrowBlack, leftArrowWhite);

    return (
        <button onClick={onClick} style={{ position: 'absolute', right:0, top: '50%', transform: 'translateY(-20%) translateX(-365%)'}}>
        <img src={prevArrowImage} alt="Next Arrow Icon" style={{ width: '70px', height: '70px' }} />
      </button>
    );
  };
  
const NextArrow = ({ onClick, currentSlide }) => {
    if (currentSlide === 4) {
        return null;
      }

    const nextArrowImage = useColorModeValue(rightArrowBlack, rightArrowWhite);

    return (
      <button onClick={onClick} style={{ position: 'absolute', right: -50, top: '50%', transform: 'translateY(-20%)' }}>
        <img src={nextArrowImage} alt="Next Arrow Icon" style={{ width: '70px', height: '70px' }} />
      </button>
    );
  };  
  
  class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0
        };
    }

    render() {    
    // this slider const is used to just create custom buttons and we set them displaywhereever we want
    // const slider = React.useRef(null);
    // const [currentSlide, setCurrentSlide] = React.useState(0);

    const { 
        quartileFilmData,
        isDocumentary,
    } = this.props;

        const settings = {
        className: 'center',
        dots: true,
        // fade: true,
        // lazyLoad: true,
        infinite: false,
        speed: 500,
        // autoplay: true,
        // autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow currentSlide={this.state.currentSlide} />,
        prevArrow: <PrevArrow currentSlide={this.state.currentSlide} />,
        afterChange: current => this.setState({ currentSlide: current }),
        };
    
        return (
        <div style={{ width: '70%', marginTop: '10px' }}>
            {/* custom button display */}
            {/* <HStack justify={'space-between'}> */}
            {/* <button onClick={() => slider?.current?.slickPrev()}>Prev</button>
            <button onClick={() => slider?.current?.slickNext()}>Next</button> */}
            {/* </HStack> */}
            
            <Slider  {...settings}>
            <div align='center'>
                <VStack>
                    <Heading letterSpacing='wider' fontFamily='categoryFont' size={'xl'}>PLOT</Heading>
                        <Center
                            w='180px'
                            h='180px'
                            border='4px'
                            borderRadius={'14'}
                            borderColor={'qPurple.400'}
                        >
                            <VStack spacing='.25'>
                                <Text marginBottom='-6' fontWeight='bold' fontFamily='numberFont' fontSize='110px' >
                                    {quartileFilmData ? quartileFilmData.filmPlotRatingAverage : '?'}
                                </Text>
                                <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                    {quartileFilmData ? 
                                        quartileFilmData.filmPlotRatingAverage === 4 ? "Well Above Average" :
                                        quartileFilmData.filmPlotRatingAverage === 3 ? "Above Average" :
                                        quartileFilmData.filmPlotRatingAverage === 2 ? "Below Average" :
                                        quartileFilmData.filmPlotRatingAverage === 1 ? "Well Below Average" :
                                        "Loading Rating" : "Not Yet Rated"}
                                </Text>
                            </VStack>
                            
                        </Center>
                </VStack>
            </div>
            <div align='center'>
                <VStack>
                    <Heading letterSpacing='wider' fontFamily='categoryFont' size={'xl'}>
                        {isDocumentary ? 'NARRATION' : 'ACTING'} 
                    </Heading>
                        <Center
                            w='180px'
                            h='180px'
                            border='4px'
                            borderRadius={'14'}
                            borderColor={'qPurple.400'}
                        >
                            <VStack spacing='.25'>
                                <Text marginBottom='-6' fontWeight='bold' fontFamily='numberFont' fontSize='110px' >
                                    {quartileFilmData ? quartileFilmData.filmActingRatingAverage : '?'}
                                </Text>
                                <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                    {quartileFilmData ? 
                                        quartileFilmData.filmActingRatingAverage === 4 ? "Well Above Average" :
                                        quartileFilmData.filmActingRatingAverage === 3 ? "Above Average" :
                                        quartileFilmData.filmActingRatingAverage === 2 ? "Below Average" :
                                        quartileFilmData.filmActingRatingAverage === 1 ? "Well Below Average" :
                                        "Loading Rating" : "Not Yet Rated"}
                                </Text>
                            </VStack>
                            
                        </Center>
                </VStack>
            </div>
            <div align='center'>
                <VStack>
                    <Heading letterSpacing='wider' fontFamily='categoryFont' size={'xl'}>CINEMATOGRAPHY</Heading>
                        <Center
                            w='180px'
                            h='180px'
                            border='4px'
                            borderRadius={'14'}
                            borderColor={'qPurple.400'}
                        >
                            <VStack spacing='.25'>
                                <Text marginBottom='-6' fontWeight='bold' fontFamily='numberFont' fontSize='110px' >
                                    {quartileFilmData? quartileFilmData.filmCinematographyRatingAverage : '?'}                                </Text>
                                <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                    {quartileFilmData ? 
                                        quartileFilmData.filmCinematographyRatingAverage === 4 ? "Well Above Average" :
                                        quartileFilmData.filmCinematographyRatingAverage === 3 ? "Above Average" :
                                        quartileFilmData.filmCinematographyRatingAverage === 2 ? "Below Average" :
                                        quartileFilmData.filmCinematographyRatingAverage === 1 ? "Well Below Average" :
                                        "Loading Rating" : "Not Yet Rated"}
                                </Text>
                            </VStack>
                            
                        </Center>
                </VStack>
            </div>
            <div align='center'>
                <VStack>
                    <Heading letterSpacing='wider' fontFamily='categoryFont' size={'xl'}>NOVELTY</Heading>
                        <Center
                            w='180px'
                            h='180px'
                            border='4px'
                            borderRadius={'14'}
                            borderColor={'qPurple.400'}
                        >
                            <VStack spacing='.25'>
                                <Text marginBottom='-6' fontWeight='bold' fontFamily='numberFont' fontSize='110px' >
                                    {quartileFilmData ? quartileFilmData.filmNoveltyRatingAverage : '?'}
                                </Text>
                                <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                    {quartileFilmData ? 
                                        quartileFilmData.filmNoveltyRatingAverage === 4 ? "Well Above Average" :
                                        quartileFilmData.filmNoveltyRatingAverage === 3 ? "Above Average" :
                                        quartileFilmData.filmNoveltyRatingAverage === 2 ? "Below Average" :
                                        quartileFilmData.filmNoveltyRatingAverage === 1 ? "Well Below Average" :
                                        "Loading Rating" : "Not Yet Rated"}
                                </Text>
                            </VStack>
                            
                        </Center>
                </VStack>
            </div>
            <div align='center'>
                <VStack>
                    <Heading letterSpacing='wider' fontFamily='categoryFont' size={'xl'}>ENDING</Heading>
                        <Center
                            w='180px'
                            h='180px'
                            border='4px'
                            borderRadius={'14'}
                            borderColor={'qPurple.400'}
                        >
                            <VStack spacing='.25'>
                                <Text marginBottom='-6' fontWeight='bold' fontFamily='numberFont' fontSize='110px' >
                                    {quartileFilmData ? quartileFilmData.filmEndingRatingAverage : '?'}
                                </Text>
                                <Text pb='2' fontFamily='paragraphFont' color='gray.500' fontSize='12px'>
                                    {quartileFilmData ? 
                                        quartileFilmData.filmEndingRatingAverage === 4 ? "Well Above Average" :
                                        quartileFilmData.filmEndingRatingAverage === 3 ? "Above Average" :
                                        quartileFilmData.filmEndingRatingAverage === 2 ? "Below Average" :
                                        quartileFilmData.filmEndingRatingAverage === 1 ? "Well Below Average" :
                                        "Loading Rating" : "Not Yet Rated"}
                                </Text>
                            </VStack>
                            
                        </Center>
                </VStack>
            </div>
            </Slider>
            

        </div>
        );
    };
};
  
  export default Carousel;
