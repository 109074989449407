import React, { useState } from 'react';
import {
    HStack,
    VStack,
    Button,
    IconButton,
    Heading, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useColorModeValue,
} from '@chakra-ui/react';
import CustomSwitch from "./CustomSwitch";

const MobileAltNav = (props) => {

    const {
        page,
        setPage,
        scene,
        setScene,
        setLoggedIn,
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');

    const CustomMenuIcon = () => (
        <img src={process.env.PUBLIC_URL + '/MenuWhite.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
      );

    const CustomMenuIconBlack = () => (
        <img src={process.env.PUBLIC_URL + '/MenuBlack.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
    );

    const callLogOut = () => {
        try {
            handleLogout();
            setPage('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const onHomeClick = () => {
        setPage('HOME');
        setScene('HOME');
    }

    return (
        <>
            <HStack marginTop={'4px'} justifyContent='space-between' width={'100%'}  >
                <VStack >
                    <Button h={'100%'} w={'100%'} variant='ghost' 
                        onClick={() => {onHomeClick()}} >
                            <VStack align='start' spacing='.1px'>
                            <Heading colorScheme ='qTurquoise' variant='custom-tq' fontFamily ="qFont" fontSize='5xl' letterSpacing='tighter'>
                            QUARTILE
                            </Heading>
                            <Heading paddingLeft='8px' fontFamily="categoryFont" fontSize='xs' letterSpacing="widest" paddingRight={'12'}>
                            Film Rating System 
                            </Heading>
                            </VStack> 
                    </Button>
                </VStack>
                   
                <VStack paddingLeft='16'paddingBottom={'6'}>
                    <Menu isLazy>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            // icon={<MyCustomIcon/>}
                            icon={useColorModeValue(<CustomMenuIconBlack/>, <CustomMenuIcon/>)}
                            variant='ghost'
                        /> 

                        <MenuList
                            style={{
                                backgroundColor: '#6c39ac',
                                borderColor: 'white',
                            }} 
                        >
                            {loggedIn ? (
                            <MenuItem 
                                // width='100%' 
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {callLogOut();}}
                                > Logout
                            </MenuItem>
                            ) : (
                            <MenuItem 
                                // width='100%' 
                                // textAlign='center'
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {
                                    setPage('LOGIN');
                                    setScene('LOGIN');
                                    }}
                                >
                                    Login
                            </MenuItem>
                            )}

                            <MenuItem
                                // width='100%' 
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {setPage('ABOUT')}}
                            >
                                About
                            </MenuItem>

                            <MenuItem> 
                                <CustomSwitch
                                onColor={'#000'}
                                offColor={'#FFF'}
                                isChecked={customSwitchChecked}
                                handleSwitch={() =>{
                                    setCustomSwitchChecked(!customSwitchChecked);
                                    toggleColorMode();
                                }}
                                >
                                </CustomSwitch>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>  
            </HStack>
        </>
    );
};

export default MobileAltNav;