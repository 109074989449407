import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Button,
    Box,
    VStack,
    Center,
    Heading,
    useToast,
} from '@chakra-ui/react';
import { registerApi} from '../../api';

const RegisterScene = (props) => {

    const {
        setScene,
        setPage,
        page,
        setLoggedIn,
        loggedIn,
        setUserId,
        customSwitchChecked,
        setCustomSwitchChecked,
        colorMode,
        toggleColorMode
    } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const isMobile = useMedia('(max-width: 767px)');

    const handleEmailInputChange = (e) => setEmail(e.target.value);
    const handlePasswordInputChange = (e) => setPassword(e.target.value);

    // make email length test longer than this
    const isEmailError = email.length >= 4 ;
    const isPasswordError = password >= 4;

    //REGISTER
    const handleRegister = async () => {
        try {
            setIsLoading(true);
            const registerResult = await registerApi({ email, password });
            setIsLoading(false);
            toast({
                title: 'Registered Account',
                description: "You've created an account under " + email + " Verify Through Your Email to Log In ",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
            // setLoggedIn(false)
            setScene('VERIFY')
        }
        catch (e) {
            setIsLoading(false);
            toast({
                title: 'Registration Failure',
                description: e.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            console.log('ERROR registering account in RegisterScene: ', e);
        }
    }

    return (
        <VStack  width ="100%" align='stretch'>
            <Center>
                <VStack p={['','72px']} width='100%' justify= 'center'   >
                    {!isMobile ? (
                        <Heading m={'-2'}  variant='custom-tq' fontFamily={"qFont"}  >
                            QUARTILE
                        </Heading>
                        ) : null} 
                    {!isMobile ? (
                        <VStack p='15' width='50%' justify= 'center'>
                            <FormControl isInvalid={isEmailError}>
                                <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                                <Input
                                    id='email'
                                    type='email'
                                    value={email}
                                    onChange={handleEmailInputChange}
                                />
                                {!isEmailError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> Email is required. </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={isPasswordError}>
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <Input
                                    id='password'
                                    type='password'
                                    value={password}
                                    onChange={handlePasswordInputChange}
                                />
                                {!isPasswordError ? (
                                    <FormHelperText>

                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage>Password is required.</FormErrorMessage>
                                )}
                            </FormControl>

                            <Box p={'2'} >
                                <Button
                                    letterSpacing='wider'
                                    isLoading={isLoading}
                                    // colorScheme='brand'
                                    colorScheme='qPurple'
                                    width ='120px'
                                    // colorScheme='red'
                                    onClick={() => {handleRegister()}}
                                    // spinner={<BeatLoader size={8} color='white' />}
                                >
                                    Sign Up
                                </Button>
                            </Box>
                        </VStack>
                        ) : 
                        <VStack paddingTop='5' width='80%' justify= 'center' spacing='0.25'>
                            <FormControl isInvalid={isEmailError}>
                                <FormLabel letterSpacing='wider'  htmlFor='email'> Email </FormLabel>
                                <Input
                                    id='email'
                                    type='email'
                                    value={email}
                                    onChange={handleEmailInputChange}
                                />
                                {!isEmailError ? (
                                    <FormHelperText>
                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage> Email is required. </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={isPasswordError}>
                                <FormLabel letterSpacing='wider' htmlFor='password'> Password </FormLabel>
                                <Input
                                    id='password'
                                    type='password'
                                    value={password}
                                    onChange={handlePasswordInputChange}
                                />
                                {!isPasswordError ? (
                                    <FormHelperText>

                                    </FormHelperText>
                                ) : (
                                    <FormErrorMessage>Password is required.</FormErrorMessage>
                                )}
                            </FormControl>

                            <Box width='100%' paddingTop={'8'} >
                                <Button
                                    letterSpacing='wider'
                                    isLoading={isLoading}
                                    // colorScheme='brand'
                                    colorScheme='qPurple'
                                    width ='120px'
                                    // colorScheme='red'
                                    onClick={() => {handleRegister()}}
                                    // spinner={<BeatLoader size={8} color='white' />}
                                >
                                    Sign Up
                                </Button>
                            </Box>
                        </VStack>
                        } 
                    </VStack>
            </Center>
        </VStack>
    );
};

export default RegisterScene;